import React, { useEffect, useState} from 'react';
import { Grid, Paper ,TextField} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { withRouter } from 'react-router';
import { checkApiStatus } from '../../utils_v2';
import { ExportMenu, PageLoader, useToastMessage } from '../../shared_elements';
import { globalGetService, globalPostService } from '../../../globalServices';
import { checkPermission } from '../../../console_management/utils_v2';

const CONSTANT = { ENGINES_FLEET_REPORT: "Engines Fleet Report", desc: "Engines Fleet report capture the asset basic details and active lease and utilization and rates information.", api: "console/export-engine-fleet-report/" }

const EngineFleetReport = ({ownerList}) => {
    const [isLoading, setLoading] = useState(false);
    const [lessor_name_id, setLessor_name_id] = useState([]);
    const [asset_type_id, setAssetTypeId] = useState([]);
    const [assetList, setAssetList] = useState([])
    const [selectAllLessor, setSelectAllLessor] = useState(false);
    const [selectAllAsset, setSelectAllAsset] = useState(false);
 const [updateForm, setUpdateForm] = useState(false);
    const toastMessage = useToastMessage()
    useEffect(() => {
        getAssetList();
    },[]);

    const getAssetList = (data) => {
        if(asset_type_id.length){
            setAssetTypeId([])
        }
        let lessorStr = data ? data.map(item => (`(${item.id},${item.lessor_level})`)).toString() : ""
        globalGetService(`/console/list/?dropdown=true&lessor_name_id=${lessorStr}`)
        .then(response => {
            if(checkApiStatus(response)){
                setAssetList(response?.data?.data?.asset);
            }
        })
    }
    const exportEnginesFleetReport = (extension) => {
        let payload = {
            file_type:extension,
            asset_id:asset_type_id.map(item=> item?.id).toString(),
            lessor_name_id:lessor_name_id.map(item => (`(${item.id},${item.lessor_level})`)).toString()
          }
            setLoading(true)
            globalGetService(CONSTANT.api, payload)
            .then(response => {
                setLoading(false);
                if(checkApiStatus(response)){
                    toastMessage(response?.data?.message, "success")
                }
                // else{
                //     toastMessage(response?.data?.message, "error")
                // }
            });
    }
    return(
        <Paper square>
            <Grid container spacing={0} alignItems='center'>
                <Grid item md={12}>
                    <div className='custom-rpt-card'>
                        <div className='rpt-card-header'>
                            <Grid container spacing={1}>
                                <Grid item md={9}>
                                    <h4>{CONSTANT.ENGINES_FLEET_REPORT}</h4>
                                    <p>{CONSTANT.desc}</p>
                                </Grid>
                                <Grid item md={3}>
                                {checkPermission('reports','custom_reports','EXP') ? 
                                    <ExportMenu 
                                        exportReportFn={(file) => exportEnginesFleetReport(file.extension)}
                                        files={[{title:'EXCEL', extension:'xlsx'}]}
                                    /> : null }
                                </Grid>
                            </Grid>
                        </div>
                        <div className='rpt-card-body'>
                            <Grid container spacing={1}>
                               
                                <Grid item md={12}>
                                    <p className='lessor-select'><span onClick={() =>  {setLessor_name_id(ownerList); setSelectAllLessor(true)}}>Select All {"Lessor"}</span>{lessor_name_id.length ? <span style={{marginLeft:'8px'}} onClick={() =>  {setLessor_name_id([]); setSelectAllLessor(false)}} >Remove All {"Lessor"}</span> : null}</p>
                                    <Autocomplete
                                        options={ownerList}
                                        getOptionLabel={option => option.name}
                                        id='lessor'
                                        value={lessor_name_id}
                                        multiple
                                        onChange={(e, data) => {setLessor_name_id(data);getAssetList(data ? data : "")}}
                                        renderInput={params => <TextField  {...params} label={"Lessor"} margin="normal" placeholder={`Select Lessor`} fullWidth InputLabelProps={{ shrink: true }} onBlur={() => setUpdateForm(false)} onFocus={() => { setUpdateForm(true); setSelectAllLessor(false)}} variant='outlined' />}
                                    />
                                </Grid>
                                <Grid item md={12}>
                                    <p className='lessor-select'><span onClick={() =>  {setAssetTypeId(assetList); setSelectAllAsset(true)}}>Select All Asset</span>{asset_type_id.length ? <span style={{marginLeft:'8px'}} onClick={() => {setAssetTypeId([]); setSelectAllAsset(false)}} >Remove All Asset</span> : null}</p>
                                    <Autocomplete
                                        options={assetList}
                                        getOptionLabel={option => option.asset_name}
                                        id='asset_type_id'
                                        value={asset_type_id}
                                        multiple
                                        onChange={(e, data) => setAssetTypeId(data)}
                                       renderInput={params => <TextField  {...params} label="Asset" margin="normal" placeholder='Select Assets' onFocus={() => { setUpdateForm(true); setSelectAllAsset(false)}} onBlur={() => setUpdateForm(false)} fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
            { isLoading ? <PageLoader/>:null}
        </Paper>
    )
}
export default withRouter(EngineFleetReport);