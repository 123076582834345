import React, { useState, useEffect } from "react";
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { backendDateFormat, fieldDateFormat, displayDateFormat } from '../../../constants';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Grid, Button, TextField, Dialog, DialogContent, DialogTitle, DialogActions, Tooltip, Divider, FormControlLabel, Checkbox, CircularProgress } from '@material-ui/core';
import { globalPostService, globalGetService } from '../../../globalServices';
import { checkApiStatus, replaceEmptyNumber } from '../../utils_v2';
import { regexConstants } from "../../../constants/regEx";
import CancelIcon from '@material-ui/icons/Cancel';
import { Alert, Autocomplete } from "@material-ui/lab";
import { InfoOutlined } from "@material-ui/icons";
import { jetStreamInstance } from "../../../shared/components";

const AddUtilization= ({ addEditUtilization, toggleModalFn, getResponseBack, params, assetInfoData, item, utilizationsInfo,majorass=false }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [majorAssemblies, setMajorAssemblies] = useState([]);
    const [utilizationsData, setUtilizationsData] = useState(addEditUtilization.data);
    const [error, setError] = useState({});
    const [isMajorAssIncluded, setIsMajorAssIncluded] = useState(false); 
    const [showNoMajorAssembliesMessage, setShowNoMajorAssembliesMessage] = useState(false);
    const handleback = () => { toggleModalFn() };
    useEffect(() => {
        if (isMajorAssIncluded && utilizationsData.length === 1) {
            let timeoutId = setTimeout(() => {
                setShowNoMajorAssembliesMessage(true);
            }, 300);
            return () => clearTimeout(timeoutId);
        } else {
            setShowNoMajorAssembliesMessage(false);
        }
        if (addEditUtilization.modal) {
            window.onpopstate = handleback;
            window.history.pushState(null, null, window.location.href);
        }
        return () => {
            window.onpopstate = null;
        };
    }, [isMajorAssIncluded, utilizationsData]);

    const fetchMajorAssemblies = () => {
        globalGetService(`console/${params.type}/${params.aircraft_slug}/billable-major-assemblies/`)
        .then(response => {
            if (checkApiStatus(response)) {
                setUtilizationsData([...utilizationsData, ...response.data.data.list]);
            }
        })
    }

    const onAddEditUtilization = () => {
        let utilError = [], isutilssError = true;
        utilError = utilizationsData?.map((utilization) => { 
            let dom;
            if (utilization.date_of_manufacture) {
                dom = utilization.date_of_manufacture;
            } else {
                
                dom = params.type === 'engine'||params.type === 'fan'||params.type === 'hpt'||params.type === 'lpt'|| params.type === 'hpc' ? assetInfoData?.manufacturing_date : assetInfoData?.date_of_manufacture;

            }
            let utilization_start_date_error = '';
            if (utilization.utilization_start_date) {
                if (utilization.utilization_start_date && !moment(utilization.utilization_start_date).isValid()) {
                    utilization_start_date_error = 'Please enter a valid Start Date.';
                } else if (moment(utilization.utilization_start_date).isAfter(moment(), 'day')) {
                    utilization_start_date_error = "Start date can't be a future date.";
                } else if (moment(utilization.utilization_start_date).isBefore(dom, 'day')) {
                    utilization_start_date_error = "Start date can't be less than the Manufacturing Date."
                } else if (utilization.asset_type === 4 && moment(utilization.utilization_start_date).isBefore(!utilization.date_of_manufacture ? moment().subtract(25, 'years') : '', 'day')) {
                    utilization_start_date_error = `Start date can't be before ${moment().format(backendDateFormat)}.`;
                }
            } else {
                utilization_start_date_error = 'Please enter Start Date.';
            }
            let utilization_date_error = '';
            if (utilization.utilization_date) {
                if (!moment(utilization.utilization_date).isValid()) {
                    utilization_date_error = 'Please enter a valid End Date.';
                } else if (moment(utilization.utilization_date).isAfter(moment(), 'day')) {
                    utilization_date_error = "End date can't be a future date.";
                } else if (moment(utilization.utilization_date).isBefore(utilization.utilization_start_date, 'day')) {
                    utilization_date_error = "End date can't be less than the Start Date."
                }
            } else {
                utilization_date_error = 'Please enter End Date.';
            }
            return {       
            utilization_date:utilization_date_error,
            utilization_start_date: utilization_start_date_error,
            tsn: utilization.tsn ? utilization?.tsn.toString()?.trim()?.length ? '' : 'Please enter TSN' : 'Please enter TSN',
            csn: utilization.csn ? utilization?.csn.toString()?.trim()?.length ? '' : 'Please enter CSN' : 'Please enter CSN',
            utilization_type:(jetStreamInstance?utilization.utilization_type: utilization.utilization_type?.trim()?.length )? '' : jetStreamInstance?'Please select Utilization Type':'Please enter Utilization Type',
            }
        });
        utilError.map(item => {
            if (!Object.keys(item).every((k) => { return item[k] === '' })) {
                isutilssError = false;
            }
        });
        if(isutilssError){
            let payload = utilizationsData.map((item) => {
                return {
                    ...item,
                    asset_location:item?.asset_location||'',
                    serviceability:item?.serviceability||'',
                    remarks:item?.remarks||'',
                    asset_id:item.id?item.id:assetInfoData.id, 
                    asset_type:item.asset_type?item.asset_type: assetInfoData.asset_type.type, 
                }
            });
            setLoading(true);
            globalPostService(`console/${params.type}/${params.aircraft_slug}/custom-utilization/`,{assets:payload})
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                    toggleModalFn(); getResponseBack();
                }
                setLoading(false)
            })
        } else {
            setError(utilError)
        }
    }
    const handleCheckboxChange = () => {
        if (!isMajorAssIncluded) {
            const majorAssembliesExist = utilizationsData.some(assembly => assembly?.name !== '');
            if (!majorAssembliesExist) {
                fetchMajorAssemblies();
            }
        } else {
            setUtilizationsData(utilizationsData.filter(assembly => assembly?.name === ''));
        }
        setIsMajorAssIncluded(!isMajorAssIncluded);
    }

    const onFieldChange = (key, value, index) => {
        
        setUtilizationsData(utilizationsData.map((item, itemIndex) => itemIndex !== index ? item: 
        key=='utilization_date' && (!value || !moment(value).isAfter(utilizationsInfo?.llp_as_of_date)) ?
        {
            ...item, [key]:value, sync_llp:false
        }:
        {
            ...item, [key]:value
        }));
    };
    const onRemoveUtilRow = (index) => {
        let updatedUtilizationsData=[...utilizationsData.slice(0, index), ...utilizationsData.slice(index + 1)];
        setUtilizationsData(updatedUtilizationsData);        
        let majorAssembliesRemoved=updatedUtilizationsData.some(assembly => assembly?.name !== '');
        if (!majorAssembliesRemoved) {
            setIsMajorAssIncluded(false);
        }
    };
    const onResetError=(key,index) =>{ error?.length ? setError(error.map((errorItem, errorIndex) => index !== errorIndex ? errorItem : { ...errorItem, [key]: '' })) : setError([])};
    const assemblytype=(params.type==='aircraft'?'AIRFRAME':params.type==='engine'?'ENGINE':params.type==='propeller'?'PROPELLER':params.type==='lg'?'LANDING GEAR':params.type==='apu'?'APU':params.type==='hpt'?'HPT':params.type==='lpt'?'LPT':params.type==='hpc'?'HPC':params.type==='fan'?'FAN':params.type==="component"?'COMPONENT':'')
    return (
        <Dialog
            open={addEditUtilization.modal}
            onClose={toggleModalFn}
            className='console-management-modal'
            fullScreen
        >
            <DialogTitle id="scroll-dialog-title">
                Add Utilization
            </DialogTitle>
            <DialogContent>
                <Alert style={{fontSize:'13px'}} severity="info">This option allows the addition of custom entries for TSN and CSN specific to certain events, such as Shop Visits, CTA entry, and lease returns etc.</Alert>
                <Paper>
                    { params.type === 'aircraft'?
                        <Grid container spacing={1} alignItems="center">
                            <Grid md={12}>
                                <ul className='list-inline flex-centered' style={{ float: 'right' }}>
                                        <li className='list-inline-item'> {showNoMajorAssembliesMessage ? <p style={{ color: 'red' }}>No Major Assemblies Linked</p> : ''}</li>
                                        <li className='list-inline-item'>
                                           <FormControlLabel style={{ float: 'right' }} control={<Checkbox size="small" color='primary' checked={isMajorAssIncluded}
                                              onChange={handleCheckboxChange}  name="include" />} label={<p style={{ fontSize: '13px' }}>Include Major Assemblies</p>} />
                                       </li>
                                </ul>
                            </Grid>
                        </Grid>:null
                    }
                </Paper>
                    <Paper style={{overflow:'auto'}}>
                    <Table className='mui-table-format' stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Assembly Type</TableCell>
                                <TableCell>Start Date*</TableCell>
                                <TableCell>End Date*</TableCell>
                                <TableCell ><div className="flex-centered">TSN*<Tooltip title={'Time Since New'}><InfoOutlined style={{ marginLeft: '5px', fontSize: '1rem', color: '' }}/></Tooltip></div></TableCell>
                                <TableCell ><div className="flex-centered">CSN*<Tooltip title={'Cycle Since New'}><InfoOutlined style={{ marginLeft: '5px', fontSize: '1rem', color: '' }}/></Tooltip></div></TableCell>
                                <TableCell>Utilization Type*</TableCell>
                                <TableCell>Location</TableCell>
                                <TableCell>Serviceability</TableCell>
                                <TableCell>Remarks</TableCell>
                               {['engine','hpc','hpt','fan','lpt'].includes(params?.type)? <TableCell> <div style={{width:'80px'}} className="flex-centered">Sync LLP<Tooltip title={'Custom utilization cannot be synced with LLP utilization if the “LLP as of date” field is blank or if the “LLP as of date” exceeds the Custom Utilization end date.'}><InfoOutlined style={{ marginLeft: '5px', fontSize: '1rem', color: '' }}/></Tooltip></div> </TableCell>:null}
                                <TableCell> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {utilizationsData.map((assembly,index)=>
                                <TableRow style={{verticalAlign: 'text-bottom' }} key={index}>
                                    <TableCell>
                                        <TextField
                                            required
                                            name={`assembly_type_${index}`}
                                            value={assembly?.name?assembly?.name:assemblytype}
                                            fullWidth
                                            margin="none"
                                            InputLabelProps={{ shrink: true }}
                                            variant='outlined'
                                            disabled
                                            style={{width:'200px'}}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                required
                                                margin="none"
                                                name={`utilization_start_date_${index}}`}
                                                format={fieldDateFormat}
                                                disableFuture
                                                InputLabelProps={{ shrink: true }}
                                                minDate={moment().subtract(25, 'years')}
                                                maxDate={moment()}
                                                value={assembly?.utilization_start_date?assembly?.utilization_start_date:null}
                                                onChange={(data, value) => { onFieldChange(`utilization_start_date`,data?moment(data).format(backendDateFormat):null, index);onResetError('utilization_start_date',index) }}
                                                inputVariant='outlined'
                                                error={error[index]&&error[index]?.utilization_start_date? true : false}
                                                helperText={error[index]&&error[index]?.utilization_start_date || ''}
                                                onFocus={() => onResetError('utilization_start_date',index)}
                                                style={{width:'200px'}}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </TableCell>
                                    <TableCell>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker
                                                required
                                                margin="none"
                                                name={`utilization_date_${index}`}
                                                format={fieldDateFormat}
                                                disableFuture
                                                InputLabelProps={{ shrink: true }}
                                                minDate={moment().subtract(25, 'years')}
                                                maxDate={moment()}
                                                value={assembly?.utilization_date?assembly?.utilization_date:null}
                                                onChange={(data, value) => { onFieldChange('utilization_date',data? moment(data).format(backendDateFormat):null, index); onResetError('utilization_date',index)}}
                                                inputVariant='outlined'
                                                error={error[index]&&error[index]?.utilization_date ? true : false}
                                                helperText={error[index]&&error[index]?.utilization_date || ''}
                                                onFocus={() => onResetError('utilization_date',index)}
                                                style={{width:'200px'}}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            required
                                            name={`tsn_${index}`}
                                            value={assembly?.tsn || ''}
                                            fullWidth
                                            margin="none"                                       
                                            inputProps={{ maxLength: 10 }}
                                            onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('tsn', e.target.value, index) : e.preventDefault()}
                                            InputLabelProps={{ shrink: true }}
                                            variant='outlined'
                                            error={error[index]&&error[index]?.tsn ? true : false}
                                            helperText={error[index]&&error[index]?.tsn || ''}
                                            onFocus={() => onResetError('tsn',index)}
                                            style={{width:'135px'}}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            required
                                            name={`csn_${index}`}                                      
                                            value={assembly?.csn || ''}
                                            fullWidth
                                            margin="none"
                                            inputProps={{ maxLength: 10 }}
                                            onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('csn', e.target.value,index) : e.preventDefault()}
                                            InputLabelProps={{ shrink: true }}
                                            variant='outlined'
                                            error={error[index]&&error[index]?.csn ? true : false}
                                            helperText={error[index]&&error[index]?.csn || ''}
                                            onFocus={() => onResetError('csn',index)}
                                            style={{width:'135px'}}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {jetStreamInstance ?
                                            (() => {
                                                let MsnOption = [{ label: 'Utilisation Update' }, { label: 'Surveillance Visit' }, { label: 'Maintenance Check' }, { label: 'CTA Delivery' }, { label: 'CTA Return' }];
                                                let otherOption = [{ label: 'Utilisation Update' }, { label: 'Surveillance Visit' }, { label: 'Shop Visit' }, { label: 'CTA Delivery' }, { label: 'CTA Return' }];
                                                let options = (params.type === 'engine' || assembly?.name.includes('Engine') ? otherOption : params.type === 'propeller' || assembly?.name.includes('Propeller') ? otherOption : params.type === 'lg'||assembly?.name.includes('LG')  ? otherOption : params.type === 'apu'||assembly?.name.includes('APU')  ? otherOption : MsnOption)
                                                return (
                                                    <Autocomplete
                                                        style={{ width: '245px' }}
                                                        options={options}
                                                        getOptionLabel={option => option.label}
                                                        value={options.find(option => option.label === assembly.utilization_type) || null}
                                                        onChange={(e, value) => onFieldChange('utilization_type', value ? value.label : '', index)}
                                                        renderInput={params => <TextField style={{ font: '10px' }} placeholder="Select Utilization Type" name={`utilization_type_${index}`} required error={error[index] && error[index]?.utilization_type ? true : false} helperText={error[index] && error[index]?.utilization_type || ''} onFocus={() => onResetError('utilization_type', index)} {...params} margin='none' fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                                    />)
                                            })()
                                            :
                                            <TextField
                                                required
                                                name={`utilization_type_${index}`}
                                                value={assembly?.utilization_type || ''}
                                                fullWidth
                                                margin="none"
                                                onChange={(e) => onFieldChange('utilization_type', e.target.value, index)}
                                                InputLabelProps={{ shrink: true }}
                                                variant='outlined'
                                                inputProps={{ maxLength: 150 }}
                                                error={error[index] && error[index]?.utilization_type ? true : false}
                                                helperText={error[index] && error[index]?.utilization_type || ''}
                                                onFocus={() => onResetError('utilization_type', index)}
                                                style={{ width: '200px' }}
                                            />}
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            name='location'
                                            value={assembly?.asset_location || ''}
                                            fullWidth
                                            margin="none"
                                            inputProps={{ maxLength: 255 }}
                                            onChange={(e) => onFieldChange('asset_location', e.target.value, index)}
                                            InputLabelProps={{ shrink: true }}
                                            variant='outlined'
                                            style={{width:'200px'}}
                                        />
                                    </TableCell>
                                    <TableCell>
                                    <TextField          
                                            name={`serviceability_${index}`}
                                            value={assembly?.serviceability || ''}
                                            fullWidth
                                            margin="none"
                                            inputProps={{ maxLength: 25 }}
                                            onChange={(e) => onFieldChange('serviceability', e.target.value, index)}
                                            InputLabelProps={{ shrink: true }}
                                            variant='outlined'
                                            style={{width:'200px'}}
                                        />
                                    </TableCell>
                                    <TableCell>
                                    <TextField          
                                            name={`remarks_${index}`}
                                            value={assembly?.remarks || ''}
                                            fullWidth
                                            margin="none"
                                            onChange={(e) => onFieldChange('remarks', e.target.value, index)}
                                            InputLabelProps={{ shrink: true }}
                                            variant='outlined'
                                            style={{width:'200px'}}
                                        />
                                    </TableCell>
                                    {['engine','hpc','hpt','fan','lpt'].includes(params?.type)?
                                    <TableCell>
                                        <FormControlLabel style={{position:'relative', top:'10px'}} control={<Checkbox size="small" disabled={assembly?.utilization_date && utilizationsInfo?.llp_as_of_date && moment(assembly?.utilization_date).isAfter(utilizationsInfo?.llp_as_of_date) ? false :true } color='primary' checked={assembly?.sync_llp ? true: false}
                                            onChange={(e) => onFieldChange('sync_llp', e.target.checked, index)} name="include" />} />
                                    </TableCell>
                                        :null}
                                    <TableCell style={{alignContent: 'center'}}>
                                    {index > 0 && (
                                    <Tooltip title='Remove Utilization' arrow>
                                    <CancelIcon onClick={() => onRemoveUtilRow(index)}  style={{ cursor: 'pointer' }} color="secondary" fontSize='small' />
                                    </Tooltip>
                                      )}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
                <Button onClick={onAddEditUtilization} disabled={isLoading} color='primary' size='small' variant='contained'>{isLoading?<CircularProgress size={24} />:'Save'}</Button>
            </DialogActions>
        </Dialog>
    )

}
export default withRouter(AddUtilization);