import React from 'react';
import { Link } from 'react-router';
import { imgStoragePath } from '../../constants';
import config from '../../config';
import { Grid, Container } from '@material-ui/core';
const Footer = ({}) => {
    return(
        <footer className='solution-footer'>
            <Container maxWidth={'lg'}>
                <Grid container spacing={1}>
                    <Grid item md={2} xs={6}>
                        <h3>Media Center</h3>
                        <ul className="list-unstyled">
                            <li><a href="https://www.acumen.aero/blogs" target="_blank">Blog</a></li>
                            <li><a href="https://www.acumen.aero/event" target="_blank">Events</a></li>
                            <li><a href="https://www.acumen.aero/press-release" target="_blank">Press Releases</a></li>
                            <li><a href="https://www.acumen.aero/market-insights" target="_blank">Resources</a></li>
                            <li><a href="https://www.acumen.aero/webinar" target="_blank">Videos & Podcast</a></li>
                        </ul>
                    </Grid>
                    <Grid item md={2} xs={6}>
                        <h3>About Us</h3>
                        <ul className="list-unstyled">
                            <li><a href="https://www.acumen.aero/leadership" target="_blank">Our Team</a></li>
                            <li><a href="https://www.acumen.aero/clients" target="_blank">Clients</a></li>
                            <li><a href="https://www.acumen.aero/careers" target="_blank">Careers</a></li>
                        </ul>
                    </Grid>
                    <Grid item md={3} xs={6}>
                        <h3>Features</h3>
                        <ul className="list-unstyled">
                            {[
                                {title:'Lease Management', path:config.domain.subDomian+'solutions?target=module-features&tabIndex=0'},
                                {title:'Records Dataroom', path:config.domain.subDomian+'solutions?target=module-features&tabIndex=3'},
                                {title:'Projects Management', path:config.domain.subDomian+'solutions?target=module-features&tabIndex=2'},
                                {title:'Maintenance Claim Management', path:config.domain.subDomian+'solutions?target=module-features&tabIndex=1'},
                                {title:'commVerge', path:config.domain.subDomian+'solutions?target=other-features&tabIndex=1'},
                                {title:'fin-S', path:config.domain.subDomian+'solutions?target=other-features&tabIndex=0'},
                                ].map(({title, path}, index) =>
                                <li key={index}>
                                    <Link href={path} className="links-footer">{title}</Link>
                                </li>
                            )}
                        </ul>
                    </Grid>
                    <Grid item md={2} xs={6}>
                        <h3>Others</h3>
                        <ul className="list-unstyled">
                            <li><a href="/termscondition" target="_blank">Terms & Conditions</a></li>
                            <li><a href="/data-policy" target="_blank">Data Policy</a></li>
                            <li><a href="https://acumen.aero/policy" target="_blank">Privacy Policy</a></li>
                            <li><a href="https://acumen.aero/policy?security=true" target="_blank">Security</a></li>
                        </ul>
                    </Grid>
                    <Grid item md={3}>
                        <h3>Connect with Us</h3>
                        <ul className="list-unstyled images-list">
                            <li><a href="https://www.facebook.com/SPARTAAero-354530545100281/" target="_blank"><img src={imgStoragePath+'phase-2/facebook.svg'}/></a></li>
                            <li><a href="https://www.instagram.com/sparta.aero/" target="_blank"><img src={imgStoragePath+'phase-2/instagram.svg'}/></a></li>
                            <li><a href="https://www.linkedin.com/company/sparta-aero" target="_blank"><img src={imgStoragePath+'phase-2/icon_LinkedIn.svg'}/></a></li>
                            <li><a href="https://twitter.com/aero_sparta" target="_blank"><img src={imgStoragePath+'phase-2/twitter.svg'}/></a></li>
                        </ul>
                        <h3 style={{ marginBottom: '6px'}}>Contact Us</h3>
                        <ul className="list-unstyled">
                            <li><a href="mailto:support@sparta.aero" target="_blank">support@sparta.aero</a></li>
                        </ul>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item md={12}>
                        <div className='copyright-section'>
                            <a href="https://acumen.aero/" target="_blank">
                                <img src={imgStoragePath+'phase-2/acumen.png'} />
                            </a>
                            <p>© 2025 Acumen Aviation Europe Limited. All rights reserved. <a href="https://acumen.aero/" target="_blank">www.acumen.aero</a></p>
                        </div>
                    </Grid>
                </Grid>
            </Container>
      </footer>
    )
}
export default Footer;