import { Chip, Grid, TextField, Button, IconButton, Checkbox, Paper, Table, TableBody, TableCell, TableHead, TableRow, Dialog, DialogContent, DialogTitle, DialogActions, CircularProgress, FormControl, FormControlLabel, Switch, Tooltip, FormLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from '../../../globalServices'
import { DeletePopUp, EmptyCollection, FilterComponent, PageLoader, Pagination, STableLoader } from '../../shared_elements';
import AddEditThirdParty from './AddEditThirdParty';
import EditIcon from '@material-ui/icons/Edit';
import { checkApiStatus, checkPermission } from '../../utils_v2';
import AddIcon from '@material-ui/icons/Add';
import ConfigSideNav from './ConfigSideNav';
import ReadMoreInline from '../../../shared/components/ReadMoreInline'
import DeleteIcon from "@material-ui/icons/Delete";
import { useSnackbar } from 'notistack';
import { contactFilterOps } from '..';
import ContactCRU from './ContactCRU';
import { DropzoneArea } from 'material-ui-dropzone';
const List = ({ item, onEdit, onDelete, onSelectRow, deleteContacts, currentFormDetail }) => {
    return (
        <TableRow hover tabIndex={-1}>
            {/* <TableCell><Checkbox checked={deleteContacts.contacts.includes(item.id)} onChange={(e) => onSelectRow(e.target.checked)} size='small' color='primary' /></TableCell>  */}
            <TableCell>{item.full_name ? item.full_name : '--'}</TableCell>
            <TableCell>{item.email ? item.email : '--'}</TableCell>
            <TableCell>{item.lessee?.name ? item.lessee?.name : '--'}</TableCell>
            <TableCell>{item.title ? item.title : '--'}</TableCell>
            <TableCell>{item.phone_number ? item.phone_number : '--'}</TableCell>
            <TableCell>{item.fax ? item.fax : '--'}</TableCell>
            <TableCell className="actions-cell">
                <div style={{ width: '70px', float: 'right' }}>
                {checkPermission('console', 'contact', 'U') ?
                    <Tooltip title="Edit">
                        <IconButton size="small" color="primary" onClick={onEdit} >
                            <EditIcon color="primary" fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    : null}
                    {checkPermission('console', 'contact', 'D') ?
                    <Tooltip title="Delete">
                        <IconButton size="small" color="primary" onClick={onDelete} >
                            <DeleteIcon color='secondary' fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    : null}
                </div>
            </TableCell>
        </TableRow>
    )
}
const ImportContacts = ({ importModal, setImportModal, onImport, isLoading }) => {
    return (
        <Dialog
            open={importModal.modal}
            className='console-management-modal '
            maxWidth="lg"
        >
            <DialogTitle id="scroll-dialog-title">
                Import Contact List
            </DialogTitle>
            <DialogContent dividers={true}>
            <div style={{ width: '270px', height:'270px' }}>
                <DropzoneArea
                    filesLimit={1}
                    maxFileSize={104857600}
                    acceptedFiles={['.xls', '.xlsx']}
                    showPreviewsInDropzone={false}
                    useChipsForPreview={true}
                    showPreviews={true}
                    dropzoneText={<p>Drag & Drop file Or Click Here </p>}
                    dropzoneClass="drag-drop-cnt"
                    maxWidth="sm"
                    showAlerts={['info', 'error']}
                    alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
                    clearOnUnmount={true}
                    onChange={(files) => setImportModal({ ...importModal, data: files })}
                />
                </div>
            </DialogContent>
            <DialogActions>
                <Button color="primary" size='small' onClick={() => { setImportModal({ modal: false }) }}>Cancel</Button>
                <Button disabled={importModal?.data?.length ? false: true} size='small' color="primary" variant="contained" onClick={onImport}>
                    {isLoading ? <CircularProgress color="#ffffff" size={24} /> : 'Save'}
                </Button>
            </DialogActions>

        </Dialog>
    )
}
export default function ContactList({ params }) {
    const [isLoading, setLoading] = useState(false)
    const [deleteContacts, setDeleteContacts] = useState({ modal: false, contacts: [] });
    const [addEditContact, setAddEditContact] = useState({ modal: false, mode: '', contacts: null });
    const [importModal, setImportModal] = useState({ modal: false });
    const [contacts, setContacts] = useState({ list: [], pagination: {} });
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [sort, setSort] = useState("");
    const [sort_by, setSortBy] = useState("");
    const [lessees, setLessee] = useState("");
    const [filter, setFilter] = useState({});
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        getContacts({}, 'skeletonLoader')
        globalGetService('console/lessees/', {})
            .then(response => {
                if (checkApiStatus(response)) {
                    setLessee(response.data.data.lessees)
                }
            })
    }, [])
    const getContacts = (query, loaderType) => {
        if (loaderType === 'skeletonLoader') { setSkeletonLoader(true) } else { setLoading(true) }
        globalGetService(`console/airline-contact/`, query)
            .then(response => {
                if (loaderType === 'skeletonLoader') { setSkeletonLoader(false) } else { setLoading(false) }
                if (checkApiStatus(response)) {
                    delete query.sort;
                    delete query.sort_by;
                    delete query.page;
                    delete query.per_page;
                    setContacts(response.data.data);
                    setFilter(query)
                }
            })
    }
    const toggleBulkOps = (flag) => {
        if (flag) {
            setDeleteContacts({ modal: false, contacts: contacts.list.map(item => item.id) })
        } else {
            setDeleteContacts({ modal: false, contacts: [] })
        }
    }
    const onSelectRow = (flag, id) => {
        if (flag) {
            setDeleteContacts({ ...deleteContacts, contacts: [...deleteContacts.contacts, id] })
        } else {
            setDeleteContacts({ ...deleteContacts, contacts: deleteContacts.contacts.filter(item => item !== id) })
        }
    }
    const onDeleteContact = () => {
        setLoading(true);
        globalDeleteService(`console/airline-contact/`, { ids: deleteContacts.contacts })
            .then(response => {
                setLoading(false);
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    setDeleteContacts({ modal: false, contacts: [] })
                    getContacts({ page: 1, per_page: contacts.pagination.per_page, ...filter }, 'pageLoader');
                } else {
                    enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                }
            })
    }
    const createSortHandler = (sortField) => {
        let sort_order = 'asc';
        if (sortField === sort) {
            sort_order = sort_by === 'asc' ? 'desc' : 'asc'
        }
        getContacts({ page: 1, per_page: contacts.pagination.per_page, sort_by: sortField, sort: sort_order }, 'pageLoader')
        setSort(sortField);
        setSortBy(sortField === sort ? sort_by === 'asc' ? 'desc' : 'asc' : 'asc')
    }
    const onImport = () => {
        console.log('Import Successful');
    }
    let filterOps = {
        ...contactFilterOps,
        lessee_id: {
            ...contactFilterOps.lessee_id,
            options: lessees
        }
    }
    return (
        <section className='config-container' style={{ margin: '24px 0 0 75px' }}>
            <Paper className="tech-specs-card" style={{ minHeight: `${window.innerHeight - 89}px` }}>
                <ConfigSideNav />
                <div className="tech-specs-content">
                    <div>
                        <Paper style={{ padding: '5px 10px' }}>
                            <Grid container spacing={1}>
                                <Grid item md={8}>
                                    <FilterComponent
                                        filter={filter}
                                        filterMenu={filterOps}
                                        getResponseBack={(applyFilter) => getContacts({ ...applyFilter }, 'pageLoader')}
                                    />
                                </Grid>
                                <Grid item md={4}>
                                    <ul className='list-inline' style={{ float: 'right' }}>
                                        {/* <li className='list-inline-item'>
                                            <Button onClick={() => setImportModal({ modal: true, data: {} })} color='primary' size='small' variant='outlined'> <AddIcon style={{ fontSize: '20px' }} /> Import contact List</Button>
                                        </li> */}
                                        {checkPermission('console', 'contact', 'C') ?
                                        <li className='list-inline-item'>
                                            <Button onClick={() => setAddEditContact({ modal: true, mode: 'Add', data: {} })} color='primary' size='small' variant='contained'>Add new contact</Button>
                                        </li>
                                        : null}
                                    </ul>
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper style={{ height: `${window.innerHeight - 192}px`, overflow: 'scroll' }}>
                            <Table className='mui-table-format'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Lessee</TableCell>
                                        <TableCell>Designation</TableCell>
                                        <TableCell>Phone Number</TableCell>
                                        <TableCell>Fax</TableCell>
                                        <TableCell align='right'>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {contacts?.list?.map((item, index) =>
                                        <List
                                            key={index}
                                            item={item}
                                            deleteContacts={deleteContacts}
                                            onEdit={() => setAddEditContact({ modal: true, mode: 'Edit', contact: item })}
                                            onDelete={() => setDeleteContacts({ modal: true, contacts: [item.id] })}
                                            onSelectRow={(flag) => onSelectRow(flag, item.id)}
                                        />
                                    )
                                    }
                                </TableBody>
                            </Table>
                            {skeletonLoader ? <STableLoader count={3} /> : null}
                            <Paper>
                                {!contacts?.list?.length && !isLoading && !skeletonLoader ? <div style={{ textAlign: 'center' }}><EmptyCollection title={'No records found'} /></div> : null}
                            </Paper>
                        </Paper>
                        <Paper>
                            <Pagination
                                pagination={contacts.pagination}
                                onChangePage={(event, newPage) => getContacts({ ...filter, page: newPage + 1, per_page: contacts.pagination.per_page, sort_by: sort_by, sort: sort }, 'pageLoader')}
                                onChangeRowsPerPage={(event) => getContacts({ ...filter, page: 1, per_page: event.target.value, sort_by: sort_by, sort: sort }, 'pageLoader')}
                            />
                        </Paper>
                    </div>
                </div>
            </Paper>
            {isLoading ? <PageLoader /> : null}
            {deleteContacts.modal ?
                <DeletePopUp
                    modal={deleteContacts.modal}
                    toggleModalFn={() => setDeleteContacts({ modal: false, contacts: [] })}
                    title="Delete Contact"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={() => onDeleteContact()}
                /> : null
            }
            {addEditContact.modal ?
                <ContactCRU
                    addEditContact={addEditContact}
                    toggleModalFn={() => setAddEditContact({ modal: false, mode: '', contact: null })}
                    getResponseBack={() => getContacts({ page: 1, per_page: contacts.pagination.per_page, ...filter }, 'pageLoader')}
                /> : null
            }
            {
                importModal?.modal ?
                    <ImportContacts
                        setImportModal={setImportModal}
                        importModal={importModal}
                        onImport={onImport}
                        isLoading={isLoading}
                    />
                    : null
            }
        </section>
    )
}
