import React from 'react'
import { useSnackbar } from 'notistack';

const useToastMessage = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const toastMessage = (resMessage, variant) => {
        enqueueSnackbar(resMessage, { variant: variant, anchorOrigin: { vertical: 'top', horizontal: 'right' } });
    }
  return toastMessage
  
}

export default useToastMessage
