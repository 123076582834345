import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Paper, Button, Grid, TextField, Tooltip } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { assetTypeValues, backendDateFormat, fieldDateFormat } from '../../../constants';
import { globalGetService, globalPutService } from '../../../globalServices';
import { checkApiStatus, checkPermission, getLocalStorageInfo, replaceEmptyNumber } from '../../utils_v2';
import { getPermissionKey } from "..";
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import { PageLoader, STableLoader } from '../../shared_elements';
import { regexConstants } from '../../../constants/regEx';
import { trackActivity } from '../../../utils/mixpanel';
import { titanAviInstance } from '../../../shared/components';
const AcquisitionDetails = ({ params, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [acquisitionDetails, setAcquisitionDetails] = useState({});
    const [error, setError] = useState({});
    useEffect(() => {
        getAcquisitionDetails('skeletonLoader');
    }, []);
    const getAcquisitionDetails = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true);
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/other_details/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setAcquisitionDetails(response.data.data);
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false);
            })
    }
    const onFieldChange = (key, value) => {
        setAcquisitionDetails({ ...acquisitionDetails, [key]: value });
    }
    const onEditAcquisitionDetails = () => {
        let dom = params.type === 'engine' ? assetInfoData?.manufacturing_date : assetInfoData?.date_of_manufacture
        let validationInputs = {
            contractual_end_date:getLocalStorageInfo()?.defaultLessor?.id === 213 && acquisitionDetails.contractual_end_date ? moment(acquisitionDetails.contractual_end_date).isSameOrAfter(dom, 'day') ? '' : "Date of Sale cannot be lesser than Date Of Manufacture" : '',
            acquisition_date: acquisitionDetails?.acquisition_date ? moment(acquisitionDetails.acquisition_date).isSameOrAfter(dom, 'day') ? '' : "Acquisition Date cannot be lesser than Date Of Manufacture" : '',
            cta_exp_date: acquisitionDetails?.cta_exp_date ? moment(acquisitionDetails?.cta_exp_date).isSameOrAfter(dom, 'day') ? '' : "CTA Date cannot be lesser than Date Of Manufacture" : '',
            delivery_date_to_current_operator: acquisitionDetails?.delivery_date_to_current_operator ? moment(acquisitionDetails?.delivery_date_to_current_operator).isSameOrAfter(dom, 'day') ? '' : "Delivery Date to Current Operator cannot be lesser than Date Of Manufacture" : '',
            next_delivery_date: acquisitionDetails?.next_delivery_date ? moment(acquisitionDetails?.next_delivery_date).isSameOrAfter(dom, 'day') ? '' : "Next Delivery Date cannot be lesser than Date Of Manufacture" : '',
        }
        if (acquisitionDetails?.acquisition_date && !moment(acquisitionDetails?.acquisition_date).isSameOrBefore(moment(), 'day')) {
            validationInputs = {
                ...validationInputs,
                acquisition_date: 'Acquisition Date cannot be future date'
            }
        }
        if (acquisitionDetails?.acquisition_date && !moment(acquisitionDetails?.acquisition_date).isValid()) {
            validationInputs = {
                ...validationInputs,
                acquisition_date: 'Enter Valid Acquisition Date'
            }
        }
        if (acquisitionDetails?.cta_exp_date && !moment(acquisitionDetails?.cta_exp_date).isSameOrBefore(moment(), 'day')) {
            validationInputs = {
                ...validationInputs,
                cta_exp_date: "CTA Date cannnot be future date"
            }
        }
        if (acquisitionDetails?.cta_exp_date && !moment(acquisitionDetails?.cta_exp_date).isValid()) {
            validationInputs = {
                ...validationInputs,
                cta_exp_date: "Enter Valid CTA Date"
            }
        }
        if (acquisitionDetails?.delivery_date_to_current_operator && !moment(acquisitionDetails?.delivery_date_to_current_operator).isSameOrBefore(moment(), 'day')) {
            validationInputs = {
                ...validationInputs,
                delivery_date_to_current_operator: "Delivery Date to Current Operator cannot be future date"
            }
        }
        if (acquisitionDetails?.delivery_date_to_current_operator && !moment(acquisitionDetails?.delivery_date_to_current_operator).isValid()) {
            validationInputs = {
                ...validationInputs,
                delivery_date_to_current_operator: "Enter Valid Delivery Date"
            }
        }
        if (acquisitionDetails?.next_delivery_date && !moment(acquisitionDetails?.next_delivery_date).isValid()) {
            validationInputs = {
                ...validationInputs,
                next_delivery_date: "Enter Valid Next Delivery Date"
            }
        }


        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true);
            globalPutService(`console/v2/asset/${params.type}/${params.aircraft_slug}/other_details/`, replaceEmptyNumber(acquisitionDetails, ['tsn_at_acquisition', 'csn_at_acquisition', 'tslsv_at_acquisition', 'cslsv_at_acquisition']))
                .then(response => {
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        trackActivity('Item Edited', { page_title: 'Aircraft Aquisition & Other Details', asset_type: assetTypeValues[assetInfoData?.asset_type.type].label, asset_name: assetInfoData?.serial_number, event_type: 'Edit Aircraft Aquisition & Other Details', event_desc: 'Edited Aircraft Aquisition & Other Details data from Edit Aircraft Aquisition & Other Details form' });
                        setEdit(false);
                    }
                    setLoading(false);
                })
        } else {
            setError(validationInputs)
        }

    }
    return (
        <div style={{ position: 'relative' }}>
            <div className="console-forms-fields" style={{ height: `${window.innerHeight - (isEdit ? 260 : 215)}px`, padding: '10px', overflow: 'auto' }}>
                <p style={{ textAlign: 'right', height: '20px' }}>
                    {checkPermission('technical_specs', getPermissionKey(params.type), 'U') && !isEdit ?
                        <span style={{ cursor: 'pointer' }}>
                            <EditIcon onClick={() => { setEdit(true); }} fontSize="small" color='primary' />
                        </span> : null
                    }
                </p>
                {skeletonLoader ? < STableLoader count={3} /> : <>
                <Grid container spacing={1}>
                    <Grid item md={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                disabled={!isEdit}
                                margin="normal"
                                name="acquisition_date"
                                label="Acquisition Date"
                                format={fieldDateFormat}
                                fullWidth
                                disableFuture
                                InputLabelProps={{ shrink: true }}
                                minDate={params.type === 'engine' ? moment(assetInfoData?.manufacturing_date) : moment(assetInfoData?.date_of_manufacture)}
                                value={acquisitionDetails?.acquisition_date || null}
                                onChange={(data, value) => { onFieldChange('acquisition_date', moment(data).format(backendDateFormat)); setError({ ...error, 'acquisition_date': '' }) }}
                                error={error?.acquisition_date ? true : false}
                                helperText={error?.acquisition_date || ''}
                                onFocus={() => setError({ ...error, 'acquisition_date': '' })}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            disabled={!isEdit}
                            name='tsn_at_acquisition'
                            label='TSN at Acquisition'
                            value={acquisitionDetails?.tsn_at_acquisition ? acquisitionDetails?.tsn_at_acquisition : ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('tsn_at_acquisition', e.target.value) : e.preventDefault()}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 10 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            disabled={!isEdit}
                            name='csn_at_acquisition'
                            label='CSN at Acquisition'
                            value={acquisitionDetails?.csn_at_acquisition ? acquisitionDetails?.csn_at_acquisition : ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('csn_at_acquisition', e.target.value) : e.preventDefault()}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 10 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            disabled={!isEdit}
                            name='acquired_from'
                            label='Acquired From'
                            value={acquisitionDetails?.acquired_from || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('acquired_from', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 25 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            disabled={!isEdit}
                            name='tslsv_at_acquisition'
                            label='TSLSV at Acquisition'
                            value={acquisitionDetails?.tslsv_at_acquisition ? acquisitionDetails?.tslsv_at_acquisition : ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('tslsv_at_acquisition', e.target.value) : e.preventDefault()}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 10 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            disabled={!isEdit}
                            name='cslsv_at_acquisition'
                            label='CSLSV at Acquisition'
                            value={acquisitionDetails?.cslsv_at_acquisition ? acquisitionDetails?.cslsv_at_acquisition : ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('cslsv_at_acquisition', e.target.value) : e.preventDefault()}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 10 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                disabled={!isEdit}
                                margin="normal"
                                name="cta_exp_date"
                                label={
                                    <span>
                                        CTA Date
                                        <Tooltip title='Certificate Of Technical Acceptance' placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                    </span>
                                }
                                format={fieldDateFormat}
                                fullWidth
                                disableFuture
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                minDate={params.type === 'engine' ? moment(assetInfoData.manufacturing_date) : moment(assetInfoData.date_of_manufacture)}
                                value={acquisitionDetails?.cta_exp_date || null}
                                onChange={(data, value) => { onFieldChange('cta_exp_date',  moment(data).format(backendDateFormat)); setError({ ...error, 'cta_exp_date': '' }) }}
                                error={error?.cta_exp_date}
                                helperText={error?.cta_exp_date || ''}
                                onFocus={() => setError({ ...error, 'cta_exp_date': '' })}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item md={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                disabled={!isEdit}
                                margin="normal"
                                name="delivery_date_to_current_operator"
                                label="Delivery Date to Current Operator"
                                format={fieldDateFormat}
                                fullWidth
                                disableFuture
                                InputLabelProps={{ shrink: true }}
                                minDate={params.type === 'engine' ? moment(assetInfoData?.manufacturing_date) : moment(assetInfoData?.date_of_manufacture)}
                                value={acquisitionDetails?.delivery_date_to_current_operator || null}
                                onChange={(data, value) => { onFieldChange('delivery_date_to_current_operator', moment(data).format(backendDateFormat)); setError({ ...error, 'delivery_date_to_current_operator': '' }) }}
                                error={error?.delivery_date_to_current_operator}
                                helperText={error?.delivery_date_to_current_operator || ''}
                                onFocus={() => setError({ ...error, 'delivery_date_to_current_operator': '' })}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={4}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                disabled={!isEdit}
                                margin="normal"
                                name="next_delivery_date"
                                label={
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        Next Delivery Date
                                        <Tooltip title="The date when the aircraft will be delivered next." placement='right'  ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                    </span>
                                }
                                format={fieldDateFormat}
                                fullWidth
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                minDate={params.type === 'engine' ? moment(assetInfoData.manufacturing_date) : moment(assetInfoData.date_of_manufacture)}
                                value={acquisitionDetails?.next_delivery_date || null}
                                onChange={(data, value) => { onFieldChange('next_delivery_date', moment(data).format(backendDateFormat)); setError({ ...error, 'next_delivery_date': '' }) }}
                                error={error?.next_delivery_date}
                                helperText={error?.next_delivery_date || ''}
                                onFocus={() => setError({ ...error, 'next_delivery_date': '' })}
                                inputVariant='outlined'
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={4}>
                        <TextField
                            disabled={!isEdit}
                            name='next_use'
                            label={
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    Next use
                                    <Tooltip title="The date when the aircraft will be available for next use." placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                </span>
                            }
                            value={acquisitionDetails?.next_use || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('next_use', e.target.value)}
                            InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                            inputProps={{ maxLength: 100 }}
                            variant='outlined'
                        />
                    </Grid>

                    <Grid item md={4}>
                            <TextField
                                disabled={!isEdit}
                                name='acquisition_price'
                                label="Acquisition Price"
                                value={acquisitionDetails?.acquisition_price || ''}
                                fullWidth
                                margin="normal"
                                inputProps={{ maxLength: 10 }}
                                onChange={(e) => onFieldChange('acquisition_price', e.target.value)}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                variant='outlined'
                            />
                        </Grid>
                  
                    {
                        getLocalStorageInfo()?.defaultLessor?.id === 213 && assetInfoData.status.value === 1 ?
                            <Grid item md={4}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        disabled={!isEdit}
                                        margin="normal"
                                        name="contractual_end_date"
                                        label="Lease End Date"
                                        format={fieldDateFormat}
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        minDate={moment(params.type === 'engine' ? assetInfoData?.manufacturing_date:assetInfoData?.date_of_manufacture)}
                                        value={acquisitionDetails?.contractual_end_date || null}
                                        onChange={(data, value) => { onFieldChange('contractual_end_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); setError({ ...error, 'contractual_end_date': '' }) }}
                                        inputVariant='outlined'
                                        error={error?.contractual_end_date}
                                        helperText={error?.contractual_end_date || ''}
                                    // onFocus={() => onResetError({ ...error, 'sold_out_date': '' })}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid> : null
                    }
                </Grid>
                </>}
            </div>
            {isEdit ?
                <Paper square style={{ padding: '10px', borderTop: '1px solid #d7d7d7' }}>
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <Button onClick={() => { setError(''); setEdit(false); getAcquisitionDetails() }} color="primary" size='small' variant="outlined">Cancel</Button>
                        </li>
                        <li className="list-inline-item">
                            <Button onClick={onEditAcquisitionDetails} color="primary" size='small' variant="contained">SAVE</Button>
                        </li>
                    </ul>
                </Paper> : null
            }
            {isLoading ? <PageLoader /> : null}
        </div>
    )
}
export default withRouter(AcquisitionDetails);