import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableHead, TableCell, TableBody, TableContainer, TableRow, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Tooltip, CircularProgress, InputLabel, Select, MenuItem } from "@material-ui/core"
import { regexConstants } from '../../../constants/regEx';

export default function ThrustField({ thurstInfo, onFieldChange, setError, error, llp }) {
    return (
        <TableContainer style={{margin:'5px'}}>
            <Table className='mui-table-format llp-thrust-table' aria-label="simple table" >
                <TableHead style={{ background: '#f0f2f3' }}>
                    <TableRow>
                        <TableCell>Thrust</TableCell>
                        <TableCell align="left">Operating Cycles</TableCell>
                        <TableCell align="left">Cycle Limit </TableCell>
                        <TableCell align="left">Remaining Cycles</TableCell>
                    </TableRow>
                </TableHead>
                { llp?.id ?
                        <TableBody>
                            {llp?.cycles_max_limit_thrusts?.map((row, index) => (
                                <TableRow >
                                    <TableCell> {llp?.cycles_max_limit_thrusts[index]?.engine_thrust_name} </TableCell>
                                    <TableCell>
                                        <TextField
                                            variant="outlined"
                                            name="csn"
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            keyParams="csn"
                                            id="csn"
                                            onChange={(e) => regexConstants.numberWithDot.test(e.target.value)? onFieldChange('operated_cycles_thrusts','csn', e.target.value, index): e.preventDefault()}
                                            value={llp?.operated_cycles_thrusts[index] && llp?.operated_cycles_thrusts[index]?.csn}
                                            />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            variant="outlined"
                                            onChange={(e) => regexConstants.numberWithDot.test(e.target.value)? onFieldChange('cycles_max_limit_thrusts','cycles_max_limit', e.target.value, index): e.preventDefault()}
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={llp?.cycles_max_limit_thrusts[index]?.cycles_max_limit}
                                            />
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="outlined"
                                            onChange={(e) => regexConstants.numberWithDot.test(e.target.value)? onFieldChange('cycles_remaining_thrusts','cycles_remaining', e.target.value, index): e.preventDefault()}
                                            disabled
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={llp?.cycles_remaining_thrusts[index]?.cycles_remaining}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        :
                        <TableBody>
                            {thurstInfo?.map((row, index) => (
                                <TableRow >
                                    <TableCell> {row?.engine_thrust_name} </TableCell>
                                    <TableCell>
                                    <TextField
                                            variant="outlined"
                                            name="csn"
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            keyParams="csn"
                                            id="csn"
                                            onChange={(e) => regexConstants.numberWithDot.test(e.target.value)? onFieldChange('operated_cycles_thrusts','csn', e.target.value, index): e.preventDefault()}
                                            value={llp?.operated_cycles_thrusts && llp?.operated_cycles_thrusts[index]?.csn}
                                            />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            variant="outlined"
                                            onChange={(e) => regexConstants.numberWithDot.test(e.target.value)? onFieldChange('cycles_max_limit_thrusts','cycles_max_limit', e.target.value, index): e.preventDefault()}
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={llp?.cycles_max_limit_thrusts && llp?.cycles_max_limit_thrusts[index]?.cycles_max_limit}
                                            />
                                    </TableCell>
                                    <TableCell>
                                        <TextField variant="outlined"
                                            onChange={(e) => regexConstants.numberWithDot.test(e.target.value)? onFieldChange('cycles_remaining_thrusts','cycles_remaining', e.target.value, index): e.preventDefault()}
                                            disabled
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={llp?.cycles_remaining_thrusts && llp?.cycles_remaining_thrusts[index]?.cycles_remaining}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                }
            </Table>
        </TableContainer>
    )
}
