import React, { useState, useEffect } from "react";
import { Button, Grid, TextField, Paper, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, CardMedia } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
import { globalDeleteService, globalGetService, globalPostService, globalPutService } from "../../../globalServices";
import { checkApiStatus, checkPermission, getLocalStorageInfo, replaceEmptyNumberZero } from "../../utils_v2";
import { DeletePopUp, EmptyCollection, GoogleLocationField, LesseeCreate, PageLoader, STableLoader } from "../../shared_elements";
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import { getPermissionKey } from "..";
import { withRouter } from "react-router";
import { regexConstants } from "../../../constants/regEx";
import LeaseStatusError from "./LeaseStatusError";
import { trackActivity } from "../../../utils/mixpanel";
import { jetStreamInstance, titanAviInstance } from "../../../shared/components";
import { leaseStatus, subStatus } from "../../fleet";
import config from '../../../config';


const AircraftOverview = ({ params, getResponseBack, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [error, setError] = useState({});
    const [basicDetails, setBasicDetails] = useState({});
    const [aircraft, setAircraft] = useState([])
    const [regions, setRegions] = useState([]);
    const [operators, setOperators] = useState([])
    const [owners, setOwners] = useState([])
    const [lessees, setLessees] = useState([]);
    const [lessors, setLessors] = useState([])
    const [pltConstants, setPltConstants] = useState([])
    const [event, setEvent] = useState([]);
    const [coverPhoto, setCoverPhoto] = useState({});
    const [previewImage, setPreviewImage] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [deleteCoverPhoto, setDeleteCoverPhoto] = useState({ modal: false });
    const [activeLeaseModal, setActiveLeaseModal] = useState({ modal: false, error: null });
    useEffect(() => {
        getBasicDetails('skeletonLoader');
        getCoverPhoto('skeletonLoader');
    }, []);
    const getDropdownData = () => {
        getRegionOfOperations();
        getLessees();
        getLessors();
        getPltConstants();
        getOperators();
        getAircraft();
        maintainanceEvent();
        getOwnersPortfolioList();
    }
    const getBasicDetails = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true);
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/basic_details/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setBasicDetails(response.data.data);
                    getResponseBack()
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false);
            })
    }
    const getRegionOfOperations = () => {
        globalGetService(`console/regions/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setRegions(response.data.data.region_list)
                }
            })
    }
    const getLessees = () => {
        globalGetService(`console/lessees/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setLessees(response.data.data.lessees)
                }
            })
    }
    const getOwnersPortfolioList = () => {
        globalGetService('console/owner/?dropdown=true&owner_portfolio=true')
            .then(response => {
                if (checkApiStatus(response)) {
                    setOwners(response.data.data);
                }
            })
    }
    const getOperators = () => {
        globalGetService(`console/operators/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setOperators(response.data.data.operators)
                }
            })
    }
    const getLessors = () => {
        globalGetService(`console/owner/?dropdown=true&lessor_use=true`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setLessors(response.data.data)
                }
            })
    }
    const getAircraft = () => {
        globalGetService(`console/aircraft-types/?lite=true`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setAircraft(response.data.data.aircraftTypes)
                }
            })
    }
    const maintainanceEvent = () => {
        globalGetService(`console/aircraft-type/${basicDetails?.aircraft_type?.id}/events/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    let mntList = [];
                    const data = response.data.data;
                    Object.keys(data).map((item) => {
                        mntList.push({ label: data[item].replace(/,/g, '/') + '', value: item })
                    });
                    setEvent(mntList);
                }
            })
    }
    const getPltConstants = () => {
        globalPostService('console/get-constants/', { constant_types: ['asset_status', 'asset_sub_status', 'asset_ownership_type', 'aircraft_operation', 'off_wing_status', 'shop_visit_type', 'workscope_status', 'sv_report_status', 'sv_requirement'] })
            .then(response => {
                if (checkApiStatus(response)) {
                    setPltConstants(response.data.data)
                }
            })
    }
    const getCoverPhoto = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true);
        globalGetService(`console/v2/asset/aircraft/${params.aircraft_slug}/cover_photo/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setCoverPhoto(response.data.data);
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false);
            })
    }
    const uploadCoverPhoto = (image) => {
        let formData = new FormData();
        formData.append('file', image);
        setLoading(true);
        globalPutService(`console/v2/asset/aircraft/${params.aircraft_slug}/cover_photo/`, formData)
            .then(response => {
                if (checkApiStatus(response)) {
                    setCoverPhoto(response.data.data);
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                }
                setLoading(false);
            })
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setPreviewImage(URL.createObjectURL(file));
            uploadCoverPhoto(file);
        }
    }
    const removeCoverPhoto = () => {
        setLoading(true);
        globalDeleteService(`console/v2/asset/aircraft/${params.aircraft_slug}/cover_photo/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' }, });
                    setDeleteCoverPhoto({ modal: false, data: null });
                    getCoverPhoto({}, 'pageLoader');
                }
                setLoading(false);
            })
    }
    const onFieldChange = (key, value) => {
        if( key === 'ownership_type' && jetStreamInstance ){
            setBasicDetails({ ...basicDetails, [key]: value, 'status':null, 'sub_status':null })
        }else if( key==='status' && jetStreamInstance ){
            setBasicDetails({ ...basicDetails, [key]: value, 'sub_status':null })
        }else{
            setBasicDetails({ ...basicDetails, [key]: value })
        }
    }
    const onEditBasicDetails = () => {
        let validationInputs = {};
        validationInputs = {
            ...validationInputs,
            sold_out_date: basicDetails?.sold_out_date ? moment(basicDetails.sold_out_date).isSameOrAfter(basicDetails.date_of_manufacture, 'day') ? '' : "Date of Sale cannot be lesser than Date Of Manufacture" : '',
            purchase_date: moment(basicDetails?.purchase_date).isValid() ? (moment(basicDetails?.purchase_date).isSameOrAfter(basicDetails?.date_of_manufacture, 'day')) ? moment(basicDetails?.purchase_date).isSameOrBefore(moment(), 'day') ? '' : "Date of Purchase cant't be future Date" : "Date of Purchase cant't be lesser than Date Of Manufacture" : '',
            country: basicDetails?.country?.trim()?.length ? '' : 'Please enter Country',
            lessor_name: basicDetails?.lessor_name ? '' : `Please select ${jetStreamInstance?"Lessor/Holdco":"Lessor"}`,
            owner: basicDetails?.owner ? '' : `Please select ${jetStreamInstance?"Owner/SPV":"Owner"}`,
            status: basicDetails?.status ? '' : 'Please select Lease Status',
            ownership_type: basicDetails?.ownership_type?.value ? '' : 'Please select Ownership Type',
            owner_portfolio: basicDetails?.ownership_type?.value === 2 && !jetStreamInstance ? basicDetails?.owner_portfolio ? '' : 'Please select Portfolio' : '',
            lessee: basicDetails?.status?.value === 1 ? basicDetails?.lessee ? '' : 'Please select Lessee' : '',
            operator: basicDetails?.status?.value === 1 && !basicDetails.same_operator_lessee ? basicDetails?.operator ? '' : 'Please select Operator' : '',
            aircraft_type: basicDetails?.aircraft_type?.name ? '' : 'Please select Aiircraft Type',
            date_of_manufacture: moment(basicDetails?.date_of_manufacture).isValid() ? moment(basicDetails.date_of_manufacture).isValid() && moment(basicDetails?.date_of_manufacture).isSameOrBefore(moment(), 'day') ? '' : "Date of Manufacture cannot be future date" : 'Please enter Date of Manufacture',
            sub_status: getLocalStorageInfo()?.defaultLessor?.id === 242 ? basicDetails.sub_status ? '' : 'Please select Sub Status' : '',
            msn: basicDetails?.msn?.trim()?.length ? '' : 'Please enter MSN',
            region: basicDetails?.region ? '' : 'Please select Region of Operation'
        }
        if (basicDetails?.sold_out_date && !moment(basicDetails?.sold_out_date).isSameOrBefore(moment(), 'day')) {
            validationInputs = {
                ...validationInputs,
                sold_out_date: "Date of Sale cannnot be future date"
            }
        }
        if (basicDetails?.status?.value === 1 && basicDetails?.lessee && basicDetails.same_operator_lessee === false && basicDetails?.operator) {
            if (basicDetails.lessee.name === basicDetails.operator.name) {
                validationInputs = {
                    ...validationInputs,
                    operator: 'Operator Should be different from Lessee'
                }
            }
        }

        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            let payload = Object.assign({}, basicDetails)
            payload = {
                ...payload,
                maintenance_event_program_group: basicDetails?.maintenance_event_program_group ? basicDetails.maintenance_event_program_group.value : null
            }
            setLoading(true)
            globalPutService(`console/v2/asset/${params.type}/${params.aircraft_slug}/basic_details/`, replaceEmptyNumberZero(payload, ['tsn_at_sold_date', 'csn_at_sold_date']))
                .then(response => {
                    if (checkApiStatus(response)) {
                        setEdit(false);
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        trackActivity('Item Edited', { page_title: 'Aircraft Basic Details', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Edit Aircraft Basic Details', event_desc: 'Edited Aircraft Basic Details data from Edit Aircraft Basic Details form' });
                        getResponseBack();
                    } else {
                        if (response.data?.statusCode === 409) {
                            setActiveLeaseModal({ modal: true, error: response.data })
                        }
                    }
                    setLoading(false)
                })
        } else {
            setError(validationInputs);
            enqueueSnackbar('Mandatory field(s) are missing', { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
        }
    }

    let jetstreamOption;
    if (basicDetails?.status?.value == 1) {
        jetstreamOption = subStatus.onLease;
    } else if (basicDetails?.status?.value == 2) {
        if (basicDetails.ownership_type == 2) {
            jetstreamOption = subStatus.offLease.filter(item => item.value !== '25');
        } else {
            jetstreamOption = subStatus.offLease;
        }
    } else if (basicDetails?.status?.value == 17) {
        jetstreamOption = subStatus.sold;
    } else if (basicDetails?.status?.value == 14) {
        jetstreamOption = subStatus.partOut;
    } else if (basicDetails?.status?.value == 15) {
        jetstreamOption = subStatus.installedOnly;
    } else if (basicDetails?.status?.value == 16) {
        jetstreamOption = subStatus.writtenOff;
    } else {
        jetstreamOption = pltConstants.filter(item => item.type === 'asset_sub_status');
    }
    const castleAirIns = (config.env.key === 'api') ? '479' :(config.env.key === 'uat')?'472': '537'
    return (
        <div style={{ position: 'relative' }}>
            <div className="console-forms-fields" style={{ padding: '10px', height: `${window.innerHeight - 220}px`, overflow: 'auto' }}>
                <p style={{ textAlign: 'right', height: '25px' }}>
                    <ul className='list-inline flex-centered' style={{ float: 'right' }}  >
                        {checkPermission('technical_specs', 'aircraft', 'U') && !isEdit ?
                            <li className='list-inline-item'>
                                <input
                                    id="cover_image"
                                    type="file"
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                    onChange={handleFileChange}
                                />
                                {coverPhoto.cover_photo ?
                                    <Button color='primary' size='small' variant='outlined' onClick={() => setOpenDialog(true)}><p style={{ fontSize: '10px' }}>View Cover Photo</p></Button>
                                    :
                                    <Button color='primary' size='small' variant='outlined' onClick={() => document.querySelector('#cover_image').click()}><p style={{ fontSize: '10px' }}>Upload Cover Photo</p></Button>
                                }
                            </li> : null}
                        <li className='list-inline-item'>
                            {checkPermission('technical_specs', 'aircraft', 'U') && !isEdit ?
                                <span style={{ cursor: 'pointer' }}>
                                    <Tooltip title={'Edit Basic Details'} style={{ pointerEvents: true }}><EditIcon onClick={() => { getDropdownData(); setEdit(true); }} fontSize="small" color='primary' /></Tooltip>
                                </span> : null
                            }
                        </li>
                    </ul>

                </p>
                {skeletonLoader ? < STableLoader count={4} /> : <>

                <Grid container spacing={1}>
                    <Grid item md={3}>
                        <TextField
                            disabled={true}
                            name='manufacturer'
                            label='Manufacturer'
                            value={basicDetails?.aircraft_type?.manufacturer?.name ? basicDetails?.aircraft_type?.manufacturer?.name : basicDetails?.manufacturer?.name || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('manufacturer', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            required
                            disabled={!isEdit}
                            name='msn'
                            label='Serial Number(MSN)'
                            value={basicDetails?.msn || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('msn', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            error={error?.msn}
                            helperText={error?.msn || ''}
                            onFocus={() => setError({ ...error, 'msn': '' })}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            disabled={true}
                            options={aircraft}
                            getOptionLabel={option => option.name}
                            name="aircraft_type"
                            value={basicDetails?.aircraft_type || null}
                            onChange={(e, value) => onFieldChange('aircraft_type', value)}
                            renderInput={params =>
                                <TextField
                                    required {...params}
                                    error={error?.aircraft_type}
                                    helperText={error?.aircraft_type || ''}
                                    onFocus={() => setError({ ...error, 'aircraft_type': '' })}
                                    label="Aircraft Type"
                                    margin="normal"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            disabled={!isEdit}
                            name='model'
                            label='Aircraft Model'
                            value={basicDetails?.model || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('model', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3}>
                        {(() => {
                            const mntEventGrp = (basicDetails?.maintenance_event_program_group) ?
                                Object.keys(basicDetails?.maintenance_event_program_group).map((item) => {
                                    let label = basicDetails?.maintenance_event_program_group[item];
                                    if (!/\bYears\b/.test(label)) {
                                        label = label?.replace(/Y(?![^Y]*Y)/, 'Years');
                                    }
                                    label = label?.replace(/Y(?!ears)/g, '');
                                    label = label?.replace(/,/g, '/');
                                    return { label: label + '', value: item };
                                }) : null;

                            let optionValue = event && mntEventGrp?.length ? mntEventGrp[0] : null
                            return (
                                <Autocomplete
                                    disabled={!isEdit ? true : (isEdit && event?.length) ? false : true}
                                    options={event}
                                    getOptionLabel={(option) => option?.label}
                                    name="maintenance_event_program_group"
                                    value={optionValue}
                                    onChange={(e, value) => onFieldChange('maintenance_event_program_group', value)}
                                    renderInput={params => <TextField {...params} label="Maintenance Program" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            )
                        })()}
                    </Grid>
                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                required
                                disabled={!isEdit}
                                margin="normal"
                                name="date_of_manufacture"
                                label="Date of Manufacture"
                                format={fieldDateFormat}
                                fullWidth
                                disableFuture
                                InputLabelProps={{ shrink: true }}
                                minDate={moment().subtract(40, 'years')}
                                value={basicDetails?.date_of_manufacture || null}
                                onChange={(data, value) => { onFieldChange('date_of_manufacture', moment(data).format(backendDateFormat)); setError({ ...error, 'date_of_manufacture': '' }) }}
                                inputVariant='outlined'
                                error={error?.date_of_manufacture}
                                helperText={error?.date_of_manufacture || ''}
                                onFocus={() => setError({ ...error, 'date_of_manufacture': '' })}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item md={3}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                                disabled={!isEdit}
                                margin="normal"
                                name="purchase_date"
                                label={
                                    <span>
                                        Date of Purchase
                                        <Tooltip title='SPA Signing Date' placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                    </span>
                                }
                                format={fieldDateFormat}
                                fullWidth
                                disableFuture
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                minDate={basicDetails?.date_of_manufacture}
                                value={basicDetails?.purchase_date || null}
                                onChange={(data, value) => { onFieldChange('purchase_date', moment(data).format(backendDateFormat)); setError({ ...error, 'purchase_date': '' }) }}
                                inputVariant='outlined'
                                error={error?.purchase_date}
                                helperText={error?.purchase_date || ''}
                                onFocus={() => setError({ ...error, 'purchase_date': '' })}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            disabled={!isEdit}
                            name='current_registration_number'
                            label='Registration'
                            value={basicDetails?.current_registration_number || ''}
                            fullWidth
                            inputProps={{ maxLength: 10 }}
                            margin="normal"
                            onChange={(e) => onFieldChange('current_registration_number', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3}>
                            <TextField
                                required
                                disabled={!isEdit}
                                name='country'
                                label={
                                    <span>
                                        Country
                                        <Tooltip title='Country of Current Registration' placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip> 
                                    </span>
                                }
                                value={basicDetails?.country || ''}
                                fullWidth
                                margin="normal"
                                onChange={(e) => onFieldChange('country', e.target.value)}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                variant='outlined'
                                error={error?.country}
                                helperText={error?.country || ''}
                                onFocus={() => setError({ ...error, 'country': '' })}
                            />
                    </Grid>

                    <Grid item md={3}>
                        {!isEdit ?
                            <TextField
                                disabled={true}
                                name='location'
                                label={
                                    <span>
                                        Location
                                        <Tooltip title='Input country of operation' placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                    </span>
                                }
                                value={basicDetails?.location || ''}
                                fullWidth
                                margin="normal"
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                variant='outlined'
                            /> :

                            <GoogleLocationField
                                onFieldChange={onFieldChange}
                                location={basicDetails?.location || ''}
                                label={
                                    <span>
                                        Location
                                        <Tooltip title='Input country of operation' placement='right' ><InfoIcon style={{ marginLeft: '5px', fontSize: '1rem', color: 'grey' }} /></Tooltip>
                                    </span>
                                }
                            />}
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            disabled={!isEdit}
                            name='airport_code'
                            label='Airport Code'
                            value={basicDetails?.airport_code || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('airport_code', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 10 }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            disabled={!isEdit}
                            options={lessors}
                            getOptionLabel={option => option.name}
                            name="lessor_name"
                            value={basicDetails?.lessor_name || null}
                            onChange={(e, value) => onFieldChange('lessor_name', value)}
                            renderInput={params =>
                                <TextField
                                    required
                                    {...params}
                                    label={jetStreamInstance?"Lessor/Holdco":"Lessor"}
                                    margin="normal"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                    error={error?.lessor_name}
                                    helperText={error?.lessor_name || ''}
                                    onFocus={() => setError({ ...error, 'lessor_name': '' })}
                                />
                            }
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            disabled={!isEdit}
                            options={lessors}
                            getOptionLabel={option => option.name}
                            name="owner"
                            value={basicDetails?.owner || null}
                            onChange={(e, value) => onFieldChange('owner', value)}
                            renderInput={params =>
                                <TextField
                                    required
                                    {...params}
                                    label={jetStreamInstance?"Owner/SPV":"Owner"}
                                    margin="normal"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    variant='outlined'
                                    error={error?.owner}
                                    helperText={error?.owner || ''}
                                    onFocus={() => setError({ ...error, 'owner': '' })}
                                />
                            }
                        />
                    </Grid>
                    <Grid item md={3}>
                        {(() => {
                            let options =[{ value: 1, label: 'Owned' }, { value: 2, label: 'Managed' }, {value:3, label:'Third Party'}];
                            return (
                                <Autocomplete
                                    disabled={!isEdit}
                                    options={!jetStreamInstance?options.filter(option=>option.value !==3):options}
                                    getOptionLabel={option => option?.label}
                                    name="ownership_type"
                                    value={basicDetails?.ownership_type || ''}
                                    onChange={(e, value) => { onFieldChange('ownership_type', value); setError({ ...error, 'ownership_type': '' }) }}
                                    renderInput={params =>
                                        <TextField
                                            required
                                            {...params}
                                            label="Ownership"
                                            margin="normal"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            variant='outlined'
                                            error={error.ownership_type}
                                            helperText={error.ownership_type || ''}
                                        />}
                                />
                            )
                        })()}

                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            disabled={(!isEdit || basicDetails?.status?.value === 5 || assetInfoData?.status?.value === 5) ? true : checkPermission('technical_specs', 'aircraft', 'STS') ? false : true}
                            options={getLocalStorageInfo()?.defaultLessor?.id !== 442 ? (pltConstants.filter(item => item?.type === 'asset_status').filter(item => item?.remarks === null).filter(item => item?.value !== 5 && item?.value !== 0)) : pltConstants.filter(item => item?.type === 'asset_status').filter(item => ![0,3,4,5,6,7,8,9,10,11].includes(item?.value) && item.remarks!== castleAirIns && (basicDetails.ownership_type?.value !== 3?item.value !==15:item.value ===15) )}
                            getOptionLabel={option => option.label}
                            name="status"
                            value={assetInfoData?.status?.value === 5 ? assetInfoData?.status : basicDetails?.status || null}
                            onChange={(e, value) => { onFieldChange('status', value); setError({ ...error, 'status': '' }) }}
                            renderInput={params => <TextField required error={error?.status} helperText={error?.status || ''} {...params} label="Lease Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            disabled={!isEdit}
                            options={jetStreamInstance?jetstreamOption:pltConstants.filter(item => item?.type === 'asset_sub_status')}
                            getOptionLabel={option => option.label}
                            name="sub_status"
                            value={basicDetails?.sub_status || null}
                            onChange={(e, value) => { onFieldChange('sub_status', value); setError({ ...error, 'sub_status': '' }) }}
                            renderInput={params => <TextField required={getLocalStorageInfo()?.defaultLessor?.id === 242} error={error?.sub_status} helperText={error?.sub_status || ''} {...params} label="Sub Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    {basicDetails?.status?.value === 4 || basicDetails?.status?.value == 17?
                        <>
                            <Grid item md={3}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        disabled={!isEdit}
                                        margin="normal"
                                        name="sold_out_date"
                                        label="Date of Sale"
                                        format={fieldDateFormat}
                                        fullWidth
                                        disableFuture
                                        InputLabelProps={{ shrink: true }}
                                        minDate={moment(assetInfoData?.date_of_manufacture)}
                                        value={basicDetails?.sold_out_date || null}
                                        onChange={(data, value) => { onFieldChange('sold_out_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); setError({ ...error, 'sold_out_date': '' }) }}
                                        inputVariant='outlined'
                                        error={error?.sold_out_date}
                                        helperText={error?.sold_out_date || ''}
                                        onFocus={() => setError({ ...error, 'sold_out_date': '' })}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item md={3}>
                                <TextField
                                    disabled={!isEdit}
                                    name='tsn_at_sold_date'
                                    label='TSN at Sale'
                                    value={basicDetails?.tsn_at_sold_date === null ? '' : basicDetails?.tsn_at_sold_date}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('tsn_at_sold_date', e.target.value) : e.preventDefault()}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 10 }}
                                    variant='outlined'
                                />
                            </Grid>
                            <Grid item md={3}>
                                <TextField
                                    disabled={!isEdit}
                                    name='csn_at_sold_date'
                                    label='CSN at Sale'
                                    value={basicDetails?.csn_at_sold_date === null ? '' : basicDetails?.csn_at_sold_date}
                                    fullWidth
                                    margin="normal"
                                    onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('csn_at_sold_date', e.target.value) : e.preventDefault()}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ maxLength: 10 }}
                                    variant='outlined'
                                />
                            </Grid>
                        </> : null
                    }
                    {basicDetails?.status?.value === 1 ?
                        <>
                            <Grid item md={3}>
                                <LesseeCreate
                                    options={lessees}
                                    required={basicDetails?.status?.value === 1 ? true : false}
                                    paramsKey='lessee'
                                    optionKey='name'
                                    label='Lessee'
                                    value={basicDetails?.lessee || null}
                                    onFieldChange={(e, paramsKey, newValue) => onFieldChange('lessee', newValue)}
                                    error={error.lessee}
                                    resetErrorKey={() => setError({ ...error, 'lessee': '' })}
                                    disabled={(!isEdit || basicDetails?.status?.value !== 1) ? true : false}
                                />
                            </Grid>

                            {(() => {
                                let options = [{ label: 'Yes', value: 1 }, { label: 'No', value: 2 }];
                                let optionValue = basicDetails?.same_operator_lessee ? { label: 'Yes', value: 1 } : { label: 'No', value: 2 }
                                return (
                                    <Grid item md={3}>
                                        <Autocomplete
                                            disableClearable={true}
                                            disabled={!isEdit}
                                            options={options}
                                            getOptionLabel={option => option.label}
                                            name="same_operator_lessee"
                                            value={optionValue}
                                            onChange={(e, value) => onFieldChange('same_operator_lessee', value?.value === 1 ? true : false)}
                                            renderInput={params => <TextField {...params} label="Operator is Same as Lessee" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                        />
                                    </Grid>
                                )
                            })()}
                            <Grid item md={3}>
                                <Autocomplete
                                    disabled={!isEdit || basicDetails?.same_operator_lessee}
                                    options={operators}
                                    getOptionLabel={option => option.name}
                                    name="operator"
                                    value={basicDetails?.same_operator_lessee ? basicDetails?.lessee : basicDetails?.operator || null}
                                    onChange={(e, value) => { onFieldChange('operator', value); setError({ ...error, 'operator': '' }) }}
                                    renderInput={params => <TextField required={basicDetails?.same_operator_lessee === false} error={basicDetails?.same_operator_lessee === false ? error.operator ? true : false : false} helperText={basicDetails?.same_operator_lessee === false ? error.operator || '' : ''} {...params} label="Operator" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                                />
                            </Grid>
                        </> : null
                    }
                    {basicDetails?.ownership_type?.value === 2 && !jetStreamInstance?
                        <Grid item md={3}>
                            <Autocomplete
                                disabled={!isEdit}
                                options={owners}
                                getOptionLabel={option => option?.name}
                                name="owner_portfolio"
                                value={basicDetails?.owner_portfolio || null}
                                onChange={(e, value) => { onFieldChange('owner_portfolio', value); setError('owner_portfolio') }}
                                renderInput={params =>
                                    <TextField
                                        required={basicDetails?.ownership_type?.value === 2 ? true : false}
                                        {...params}
                                        label="Portfolio"
                                        margin="normal"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        variant='outlined'
                                        error={error.owner_portfolio ? true : false}
                                        helperText={error.owner_portfolio || ''}
                                    />}
                            />
                        </Grid> : null
                    }
                    <Grid item md={3}>
                        <Autocomplete
                            disabled={!isEdit}
                            options={regions}
                            getOptionLabel={option => option.name}
                            name="region"
                            value={basicDetails?.region || null}
                            onChange={(e, value) => { onFieldChange('region', value); setError({ ...error, 'region': '' }) }}
                            renderInput={params => <TextField required {...params} error={error?.region} helperText={error?.region || ''} label="Region Of Operation" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <Autocomplete
                            disabled={!isEdit}
                            options={pltConstants.filter(item => item?.type === 'aircraft_operation')}
                            getOptionLabel={option => option.label}
                            name="aircraft_operation"
                            value={basicDetails?.aircraft_operation || null}
                            onChange={(e, value) => onFieldChange('aircraft_operation', value)}
                            renderInput={params => <TextField {...params} label="Aircraft Config" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                        />
                    </Grid>
                    <Grid item md={3}>
                        <TextField
                            disabled={!isEdit}
                            name='aoc_regulation'
                            label='AOC Regulation'
                            value={basicDetails?.aoc_regulation || ''}
                            fullWidth
                            margin="normal"
                            onChange={(e) => onFieldChange('aoc_regulation', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>

                    <Grid item md={12}>
                        <TextField
                            disabled={!isEdit}
                            name='technical_disclaimer'
                            label='Technical Disclaimer'
                            value={basicDetails?.technical_disclaimer || ''}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={3}
                            inputProps={{ maxLength: 255 }}
                            onChange={(e) => onFieldChange('technical_disclaimer', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            disabled={!isEdit}
                            name='marketing_disclaimer'
                            label='Marketing Disclaimer'
                            multiline
                            rows={3}
                            value={basicDetails?.marketing_disclaimer || ''}
                            fullWidth
                            margin="normal"
                            inputProps={{ maxLength: 255 }}
                            onChange={(e) => onFieldChange('marketing_disclaimer', e.target.value)}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                        />
                    </Grid>
                </Grid></>}
                {isEdit ? <div className="divider" style={{ height: '51px' }}></div> : null}
            </div>
            {isEdit ?
                <Paper square style={{ padding: '10px', borderTop: '1px solid #d7d7d7', position: 'absolute', bottom: '0', left: '0', width: '100%', background: '#fff', zIndex: 9 }}>
                    <ul className='list-inline'>
                        <li className='list-inline-item'>
                            <Button onClick={() => { setError(''); setEdit(false); getBasicDetails() }} color='primary' variant='outlined' size='small'>Cancel</Button>
                        </li>
                        <li className='list-inline-item'>
                            <Button onClick={onEditBasicDetails} color='primary' variant='contained' size='small'>Save</Button>
                        </li>
                    </ul>
                </Paper> : null
            }
            {activeLeaseModal.modal ?
                <LeaseStatusError
                    activeLeaseModal={activeLeaseModal}
                    toggleModalFn={() => setActiveLeaseModal({ modal: false, error: null })}
                /> : null
            }
            {openDialog ?
                <Dialog
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    className='console-management-modal'
                    maxWidth={'md'}
                >
                    <DialogTitle id="scroll-dialog-title">Cover Photo Preview</DialogTitle>
                    <DialogContent dividers={true} style={{ minWidth: '500px', maxWidth: "900px", minHeight: '200px' }}>
                        <ul className='list-inline' style={{ marginBottom: '8px' }}>
                            <li className='list-inline-item'>
                                <Button style={{ fontSize: "10px" }} color='primary' size='small' variant='outlined' onClick={() => document.querySelector('#cover_image').click()} > Update Cover Photo  </Button>
                            </li>
                            {coverPhoto.cover_photo ?
                                <li className='list-inline-item'>
                                    <Button style={{ fontSize: "10px" }} color='secondary' size='small' variant='outlined' onClick={() => setDeleteCoverPhoto({ modal: true })} > Remove </Button>
                                </li> : null
                            }
                        </ul>
                        {coverPhoto.cover_photo && (
                            <img
                                src={coverPhoto.url}
                                alt="Cover Photo Preview"
                                style={{ maxWidth: "auto" }}
                            />
                        )}
                        {!coverPhoto.cover_photo ? <div style={{ textAlign: 'center', border: 'dotted', color: '#d2e1e7', padding: '50px' }}><EmptyCollection title={<h4 style={{ color: '#a0bdc8' }}>No Cover Photo found</h4>} /></div> : null}
                    </DialogContent>
                    <DialogActions>
                        <Button color='primary' size='small' variant='outlined' onClick={() => setOpenDialog(false)}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog> : null
            }
            {deleteCoverPhoto.modal ?
                <DeletePopUp
                    modal={deleteCoverPhoto.modal}
                    title='Remove Cover Photo'
                    content={`Are you sure, you want to Remove?`}
                    toggleModalFn={() => setDeleteCoverPhoto({ modal: false, data: null })}
                    deleteRecordFn={removeCoverPhoto}
                    confirmText={`Remove`}
                /> : null
            }
            {isLoading ? <PageLoader /> : null}
        </div>
    )
}
export default withRouter(AircraftOverview);