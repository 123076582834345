import React, { useState, useEffect } from 'react';
import { Table, Grid, TableHead, TableRow, TableCell, TableBody, TextField, Paper, Button } from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { globalGetService, globalPostService, globalPutService } from '../../../globalServices';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
import { withRouter } from 'react-router';
import { checkApiStatus, checkPermission, replaceEmptyNumberZero } from '../../utils_v2';
import { useSnackbar } from 'notistack';
import { regexConstants } from '../../../constants/regEx';
import { weightObj } from '..';
import { PageLoader, STableLoader } from '../../shared_elements';
import { Skeleton } from '@material-ui/lab';
import { trackActivity } from '../../../utils/mixpanel';
const WeightRowItem = ({ isEdit, keyParam, onFieldChange, error, kgsValue, lbsValue }) => {
    return (
        <TableCell className='content-cell'>
            <Table className='inner-table'>
                <TableRow>
                    <TableCell>
                        <TextField
                            disabled={!isEdit}
                            fullWidth
                            margin="normal"
                            value={lbsValue}
                            InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                            variant='outlined'
                            onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange(keyParam, e.target.value) : e.preventDefault()}
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                            disabled={!isEdit}
                            fullWidth
                            margin="normal"
                            value={kgsValue}
                            error={error}
                            InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                            variant='outlined'
                            onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange(`${keyParam}_kgs`, e.target.value) : e.preventDefault()}
                        />
                    </TableCell>
                </TableRow>
            </Table>
        </TableCell>
    )
}
const AircraftWeight = ({ params, assetInfoData }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [weightDetails, setWeightDetails] = useState({});
    const [weightConstant, setWeightConstant] = useState({})
    const [error, setError] = useState({});
    useEffect(() => {
        getWeightDetails('skeletonLoader');
        getWeightConstant()
    }, []);
    const getWeightDetails = (loaderType) => {
        loaderType === 'skeletonLoader' ? setSkeletonLoader(true) : setLoading(true);
        globalGetService(`console/v2/asset/${params.type}/${params.aircraft_slug}/weight_variants/`)
            .then(response => {
                if (checkApiStatus(response)) {
                    setWeightDetails(response.data.data);
                }
                loaderType === 'skeletonLoader' ? setSkeletonLoader(false) : setLoading(false);
            })
    }
    const getWeightConstant = () => {
        globalPostService('console/get-constants/', { constant_types: ['weight_unit'] })
            .then(response => {
                if (checkApiStatus(response)) {
                    setWeightConstant(response.data.data)
                }
            })
    }
    const editWeightDetails = () => {
        let validationInputs = {
            date_last_weighing: weightDetails?.date_last_weighing ? moment(weightDetails.date_last_weighing).isSameOrAfter(assetInfoData?.date_of_manufacture, 'day') ? '' : "Date Last Weighing cannot be lesser than Date Of Manufacture" : '',
        }
        // if (weightDetails?.date_last_weighing){
        //     if(!moment(weightDetails?.date_last_weighing).isValid()){
        //         validationInputs = {
        //             ...validationInputs,
        //             date_last_weighing:'Please enter valid date '
        //         } 
        //     }

        // }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true);
            globalPutService(`console/v2/asset/${params.type}/${params.aircraft_slug}/weight_variants/`, replaceEmptyNumberZero(weightDetails, ['purchase_max_zero_fuel_weight', 'fuel_capacity_gal', 'fuel_capacity', 'fuel_capacity_density', 'purchase_max_zero_fuel_weight_kgs', 'purchase_basic_operating_weight', 'purchase_basic_operating_weight_kgs', 'purchase_max_take_off_weight', 'purchase_max_take_off_weight_kgs', 'purchase_max_landing_weight', 'purchase_max_landing_weight_kgs', 'purchase_max_taxi_weight', 'purchase_max_taxi_weight_kgs', 'purchase_basic_empty_weight',
                'purchase_basic_empty_weight_kgs', 'operating_max_zero_fuel_weight', 'operating_max_zero_fuel_weight_kgs', 'operating_max_take_off_weight', 'operating_max_take_off_weight_kgs', 'operating_max_landing_weight', 'operating_max_landing_weight_kgs', 'operating_max_taxi_weight', 'operating_max_taxi_weight_kgs', 'operating_basic_empty_weight', 'operating_basic_empty_weight_kgs', 'operating_basic_operating_weight', 'operating_basic_operating_weight_kgs', 'delivered_max_zero_fuel_weight',
                'delivered_max_zero_fuel_weight_kgs', 'delivered_max_take_off_weight', 'delivered_max_take_off_weight_kgs', 'delivered_max_landing_weight', 'delivered_max_landing_weight_kgs', 'delivered_max_taxi_weight', 'delivered_max_taxi_weight_kgs', 'delivered_basic_empty_weight', 'delivered_basic_empty_weight_kgs', 'delivered_basic_operating_weight',
                'delivered_basic_operating_weight_kgs']))
                .then(response => {
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        trackActivity('Item Edited', { page_title: 'Aircraft Weight Details', asset_type: 'MSN', asset_name: assetInfoData?.serial_number, event_type: 'Edit Aircraft Weight Details', event_desc: 'Edited Aircraft Weight Details data from Edit Aircraft Weight Details form' });
                        setEdit(false);
                        getWeightDetails('skeletonLoader');
                    }
                    setLoading(false)
                })
        } else {
            setError(validationInputs)
        }
    }
    const onFieldChange = (key, value) => {
        if (key === 'fuel_capacity') {
            setWeightDetails({
                ...weightDetails,
                [key]: value,
                fuel_capacity_gal: parseFloat(parseFloat(value * 0.2641720524).toFixed(2))
            })
        } else if (key === 'fuel_capacity_gal') {
            setWeightDetails({
                ...weightDetails,
                [key]: value,
                fuel_capacity: parseFloat(parseFloat(value * 3.785411784 ).toFixed(2))
            });
        } else {
            setWeightDetails({ ...weightDetails, [key]: value });
        }
    }
    const updateWeights = (key, value) => {
        if (key?.includes('_kgs')) {
            let lbKey = key.substr(0, key.indexOf('_kg'));
            setWeightDetails({
                ...weightDetails,
                [key]: value,
                [lbKey]: parseFloat(parseFloat(value * 2.205).toFixed(3))
            });
        } else {
            let kgKey = key + '_kgs';
            setWeightDetails({
                ...weightDetails,
                [key]: value,
                [kgKey]: parseFloat(parseFloat(value / 2.205).toFixed(3))
            });
        }
    };
    return (
        <div style={{ position: 'relative' }}>
            <div className='console-forms-fields' style={{ padding: '10px', height: `${window.innerHeight - 220}px`, overflow: 'auto' }}>
                <p style={{ textAlign: 'right', height: '20px' }}>
                    {checkPermission('technical_specs', 'aircraft', 'U') && !isEdit ?
                        <span style={{ cursor: 'pointer' }}>
                            <EditIcon onClick={() => { setEdit(true); }} fontSize="small" color='primary' />
                        </span> : null
                    }
                </p>
                {skeletonLoader ? <STableLoader count={4}/> : <>
                    <Grid container spacing={1}>
                        <Grid item md={3}>
                            <TextField
                                disabled={!isEdit}
                                name='fuel_capacity'
                                label='Fuel Capacity (Ltr)'
                                value={weightDetails?.fuel_capacity || ''}
                                fullWidth
                                margin="normal"
                                inputProps={{ maxLength: 10 }}
                                onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('fuel_capacity', e.target.value) : e.preventDefault()}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                disabled={!isEdit}
                                name='fuel_capacity_gal'
                                label='Fuel Capacity (US Gal)'
                                value={weightDetails?.fuel_capacity_gal || ''}
                                fullWidth
                                margin="normal"
                                inputProps={{ maxLength: 10 }}
                                onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('fuel_capacity_gal', e.target.value) : e.preventDefault()}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                disabled={!isEdit}
                                name='ftis'
                                label='Fuel Tank Inerting System'
                                value={weightDetails?.ftis || ''}
                                fullWidth
                                margin="normal"
                                inputProps={{ maxLength: 10 }}
                                onChange={(e) => onFieldChange('ftis', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                disabled={!isEdit}
                                name='fuel_capacity_density'
                                label='Fuel Density'
                                value={weightDetails?.fuel_capacity_density || ''}
                                fullWidth
                                inputProps={{ maxLength: 10 }}
                                margin="normal"
                                onChange={(e) => regexConstants.decimalnodotstart.test(e.target.value) ? onFieldChange('fuel_capacity_density', e.target.value) : e.preventDefault()}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                disabled={!isEdit}
                                name='additional_center_tanks'
                                label='Additional Center Tanks'
                                value={weightDetails?.additional_center_tanks || ''}
                                fullWidth
                                margin="normal"
                                inputProps={{ maxLength: 10 }}
                                onChange={(e) => onFieldChange('additional_center_tanks', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                disabled={!isEdit}
                                name='landing_capability'
                                label='Landing Capability'
                                value={weightDetails?.landing_capability || ''}
                                fullWidth
                                margin="normal"
                                inputProps={{ maxLength: 10 }}
                                onChange={(e) => onFieldChange('landing_capability', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    disabled={!isEdit}
                                    margin="normal"
                                    name="date_last_weighing"
                                    label="Date Last Weighing"
                                    format={fieldDateFormat}
                                    fullWidth
                                    disableFuture
                                    InputLabelProps={{ shrink: true }}
                                    minDate={assetInfoData.date_of_manufacture}
                                    value={weightDetails?.date_last_weighing || null}
                                    onChange={(data, value) => { onFieldChange('date_last_weighing', moment(data).isValid() ? moment(data).format(backendDateFormat) : null); setError({ ...error, 'date_last_weighing': '' }) }}
                                    inputVariant='outlined'
                                    error={error?.date_last_weighing ? true : false}
                                    helperText={error?.date_last_weighing || ''}
                                // onFocus={() => setError({ ...error, 'date_last_weighing': '' })}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                disabled={!isEdit}
                                name='weight_variant'
                                label='Weight Variant'
                                value={weightDetails?.weight_variant || ''}
                                fullWidth
                                margin="normal"
                                inputProps={{ maxLength: 25 }}
                                onChange={(e) => onFieldChange('weight_variant', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                variant='outlined'
                            />
                        </Grid>
                    </Grid>
                    <div className='aircraft-weight-table' style={{ paddingTop: '25px' }}>
                        <Paper >
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className='header-cell-border' style={{ pointerEvents: 'none' }}></TableCell>
                                        {['Purchased', 'Delivered', 'Current'].map((label, index) =>
                                            <TableCell key={index} className='header-cell-border' style={{ pointerEvents: 'none' }}>
                                                <p>{label}</p>
                                                <Table className='inner-table-hd' style={{ pointerEvents: 'none' }}>
                                                    <TableRow>
                                                        <TableCell >{weightConstant[1]?.label}</TableCell>
                                                        <TableCell>{weightConstant[0]?.label}</TableCell>
                                                    </TableRow>
                                                </Table>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                </TableHead>
                                <TableBody >
                                    <TableRow>
                                        <TableCell style={{ backgroundColor: "#b6bedc" }}>Basic Empty Weight</TableCell>
                                        <WeightRowItem isEdit={isEdit} keyParam='purchase_basic_empty_weight' onFieldChange={(key, value) => updateWeights(key, value)} kgsValue={weightDetails.purchase_basic_empty_weight_kgs} lbsValue={weightDetails.purchase_basic_empty_weight} />
                                        <WeightRowItem isEdit={isEdit} keyParam='delivered_basic_empty_weight' onFieldChange={(key, value) => updateWeights(key, value)} kgsValue={weightDetails.delivered_basic_empty_weight_kgs} lbsValue={weightDetails.delivered_basic_empty_weight} />
                                        <WeightRowItem isEdit={isEdit} keyParam='operating_basic_empty_weight' onFieldChange={(key, value) => updateWeights(key, value)} kgsValue={weightDetails.operating_basic_empty_weight_kgs} lbsValue={weightDetails.operating_basic_empty_weight} />
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ backgroundColor: "#b6bedc" }}>Max Landing Weight</TableCell>
                                        <WeightRowItem isEdit={isEdit} keyParam='purchase_max_landing_weight' onFieldChange={(key, value) => updateWeights(key, value)} kgsValue={weightDetails.purchase_max_landing_weight_kgs} lbsValue={weightDetails.purchase_max_landing_weight} />
                                        <WeightRowItem isEdit={isEdit} keyParam='delivered_max_landing_weight' onFieldChange={(key, value) => updateWeights(key, value)} kgsValue={weightDetails.delivered_max_landing_weight_kgs} lbsValue={weightDetails.delivered_max_landing_weight} />
                                        <WeightRowItem isEdit={isEdit} keyParam='operating_max_landing_weight' onFieldChange={(key, value) => updateWeights(key, value)} kgsValue={weightDetails.operating_max_landing_weight_kgs} lbsValue={weightDetails.operating_max_landing_weight} />
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ backgroundColor: "#b6bedc" }}>Max Take Off Weight</TableCell>
                                        <WeightRowItem isEdit={isEdit} keyParam='purchase_max_take_off_weight' onFieldChange={(key, value) => updateWeights(key, value)} kgsValue={weightDetails.purchase_max_take_off_weight_kgs} lbsValue={weightDetails.purchase_max_take_off_weight} />
                                        <WeightRowItem isEdit={isEdit} keyParam='delivered_max_take_off_weight' onFieldChange={(key, value) => updateWeights(key, value)} kgsValue={weightDetails.delivered_max_take_off_weight_kgs} lbsValue={weightDetails.delivered_max_take_off_weight} />
                                        <WeightRowItem isEdit={isEdit} keyParam='operating_max_take_off_weight' onFieldChange={(key, value) => updateWeights(key, value)} kgsValue={weightDetails.operating_max_take_off_weight_kgs} lbsValue={weightDetails.operating_max_take_off_weight} />
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ backgroundColor: "#b6bedc" }}>Max Taxi Weight</TableCell>
                                        <WeightRowItem isEdit={isEdit} keyParam='purchase_max_taxi_weight' onFieldChange={(key, value) => updateWeights(key, value)} kgsValue={weightDetails.purchase_max_taxi_weight_kgs} lbsValue={weightDetails.purchase_max_taxi_weight} />
                                        <WeightRowItem isEdit={isEdit} keyParam='delivered_max_taxi_weight' onFieldChange={(key, value) => updateWeights(key, value)} kgsValue={weightDetails.delivered_max_taxi_weight_kgs} lbsValue={weightDetails.delivered_max_taxi_weight} />
                                        <WeightRowItem isEdit={isEdit} keyParam='operating_max_taxi_weight' onFieldChange={(key, value) => updateWeights(key, value)} kgsValue={weightDetails.operating_max_taxi_weight_kgs} lbsValue={weightDetails.operating_max_taxi_weight} value={weightDetails.operating_max_taxi_weight === null ? '' : weightDetails.operating_max_taxi_weight} />
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ backgroundColor: "#b6bedc" }}>Max Zero Fuel Weight</TableCell>
                                        <WeightRowItem isEdit={isEdit} keyParam='purchase_max_zero_fuel_weight' onFieldChange={(key, value) => updateWeights(key, value)} kgsValue={weightDetails.purchase_max_zero_fuel_weight_kgs} lbsValue={weightDetails.purchase_max_zero_fuel_weight} />
                                        <WeightRowItem isEdit={isEdit} keyParam='delivered_max_zero_fuel_weight' onFieldChange={(key, value) => updateWeights(key, value)} kgsValue={weightDetails.delivered_max_zero_fuel_weight_kgs} lbsValue={weightDetails.delivered_max_zero_fuel_weight} />
                                        <WeightRowItem isEdit={isEdit} keyParam='operating_max_zero_fuel_weight' onFieldChange={(key, value) => updateWeights(key, value)} kgsValue={weightDetails.operating_max_zero_fuel_weight_kgs} lbsValue={weightDetails.operating_max_zero_fuel_weight} />
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Paper>
                    </div></>}
                {isEdit ? <div className="divider" style={{ height: '51px' }}></div> : null}
            </div>
            {isEdit ?
                <Paper square style={{ padding: '10px', borderTop: '1px solid #d7d7d7', position: 'absolute', bottom: '0', left: '0', width: '100%', background: '#fff', zIndex: 9 }}>
                    <ul className='list-inline'>
                        <li className='list-inline-item'>
                            <Button onClick={() => { setError(''); setEdit(false); getWeightDetails() }} color='primary' variant='outlined' size='small'>Cancel</Button>
                        </li>
                        <li className='list-inline-item'>
                            <Button onClick={editWeightDetails} color='primary' variant='contained' size='small'>Save</Button>
                        </li>
                    </ul>
                </Paper> : null
            }
            {isLoading ? <PageLoader /> : null}
        </div>
    )
}
export default withRouter(AircraftWeight);