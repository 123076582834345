import React from 'react';
import { Container, Button } from '@material-ui/core';
import avolonIcon from '../../../shared/assets/img/avolon_logo.png';
import regionalOneIcon from '../../../shared/assets/img/regionalone_logo.png';
import capgeminiIcon from '../../../shared/assets/img/capgemini_logo.png';
import fpgIcon from '../../../shared/assets/img/fpg_logo.png';
import wngIcon from '../../../shared/assets/img/wng_logo.png';
import downArrow from '../../../shared/assets/img/arrow_down_icon.svg';
import jetstreamIcon from '../../../shared/assets/img/jetstream_logo.png';
import hrAviation from '../../../shared/assets/img/hrAviationNew.png';
import bocAviation from '../../../shared/assets/img/boc.png';
import dialLogo from '../../../shared/assets/img/dial_logo.png';
import smbcLogo from '../../../shared/assets/img/smbc_logo.png';
import hanwah from '../../assets/images/hanwah.jpg'
import halcyon from '../../assets/images/halcyonNew.jpeg';
import gaTelesisLogo from '../../../shared/assets/img/ga_telesis_logo.svg'
import titalAviationLogo from '../../../shared/assets/img/titan_aviation_logo.svg'
import ablAviationLogo from '../../../shared/assets/img/ABL_Aviation_logo.svg'
import { trackFeatureActivity } from '../../../utils/mixpanel';
const ModulesFeatures = ({scrollToNextSec, toggleRequestDemoModal}) => {
  return(
    <section className="partners-section" id="partners">
      <Container maxWidth="xl">
        <div className="module-header">
          <h2>Our Partners are reaching full potential.</h2>
        </div>
        <div className='partner-wrapper'>
          <ul className="list-inline">
            <li className='list-inline-item'><img className="slider-img-partners" src={avolonIcon} alt="Icon" /></li>
            <li className='list-inline-item'><img className="slider-img-partners" src={regionalOneIcon} alt="Icon" /></li>
            <li className='list-inline-item'><img className="slider-img-partners" src={fpgIcon} alt="Icon" /></li>
            <li className='list-inline-item'><img className="slider-img-partners" src={wngIcon} alt="Icon" /></li>
            <li className='list-inline-item'><img className="slider-img-partners" src={jetstreamIcon} alt="Icon" style={{ width: '130px' }} /></li>
            <li className='list-inline-item'><img className="slider-img-partners" src={hrAviation} alt="Icon" style={{ width: '140px', marginLeft:'16px' }} /></li>
            <li className='list-inline-item'><img className="slider-img-partners" src={bocAviation} alt="Icon" style={{ width: '140px', marginLeft:'20px' }} /></li>
            <li className='list-inline-item'><img className="slider-img-partners" src={dialLogo} alt="Icon" style={{ width: '120px', height:'52px', marginTop:'8px', marginLeft:'21px' }} /></li>
            <li className='list-inline-item'><img className="slider-img-partners" src={smbcLogo} alt="Icon" style={{ width: '120px', marginTop:'10px', marginLeft:'30px' }} /></li>
            <li className='list-inline-item'><img className="slider-img-partners" src={hanwah} alt="Icon" style={{ width: '170px', marginTop:'10px', marginLeft:'30px' }} /></li>
            <li className='list-inline-item'><img className="slider-img-partners" src={halcyon} alt="Icon" style={{ width: '120px', marginTop:'10px', marginLeft:'30px' }} /></li>
            <li className='list-inline-item'><img className="slider-img-partners" src={titalAviationLogo} alt="Icon" style={{ width: '120px', marginTop:'5px', marginLeft:'30px' }} /></li>
            <li className='list-inline-item'><img className="slider-img-partners" src={ablAviationLogo} alt="Icon" style={{ width: '120px', marginTop:'5px', marginLeft:'18px', height:"45px" }} /></li>
            <li className='list-inline-item'><img className="slider-img-partners" src={gaTelesisLogo} alt="Icon" style={{ width: '55px', marginTop:'3px', height:'50px', marginLeft:'10px' }} /></li>
          </ul>
        </div>
        <div className="aviation-business-card">
          <h4>Put a jetpack on your business ambitions with SPARTA</h4>
          <p>See how SPARTA can integrate within your business processes and maximise efficiency.</p>
          <Button color="primary" className="btn-color" variant="contained" size="medium" style={{ marginTop: '40px' }} onClick={() => { toggleRequestDemoModal(); trackFeatureActivity('Item Clicked ', { page_title: 'Solutions', page_section: 'Aviation Business', item_type: 'Event', item_name: 'Get Started' }) }}>Get Started</Button>
        </div>
        <div className="down-arrow partners">
          <img src={downArrow} alt="Arrow Icon" onClick={scrollToNextSec} />
        </div>
      </Container>
    </section>
  )
}
export default ModulesFeatures;
